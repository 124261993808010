export let DATE_STRING_FORMAT = 'MM/DD/YYYY hh:mm A z';
export let DATE_PART_STRING_FORMAT = 'MMMM DD, YYYY';
export let TIME_PART_STRING_FORMAT = 'hh:mm A z';

export let STREAM_TIMEOUT_INTERVAL = 1800000;
export let PROFILE_REFRESH_INTERVAL = 60000;
export let CAMERA_REFRESH_INTERVAL = 60000;
export let DEFAULT_ORGANIZATION_ID = -1;
export let DEFAULT_USER_SUB = '00000000-0000-0000-0000-000000000000';
export let DEFAULT_QUERY = '';
export let DEFAULT_PAGE_INDEX = 0;
export let DEFAULT_PAGE_SIZE = 10;
export let DEFAULT_TOTAL_PAGES = 0;
export let ALL_ORGANIZATION_ID = 0;

export let ORGANIZATION_NAME = 'ORGANIZATION_NAME';
export let ORGANIZATION_DESCRIPTION = 'ORGANIZATION_DESCRIPTION';
export let ORGANIZATION_ROLES = 'ORGANIZATION_ROLES';
export let ORGANIZATION_DATASOURCES = 'ORGANIZATION_DATASOURCES';
export let ORGANIZATION_PARENT_ORGANIZATION = 'ORGANIZATION_PARENT_ORGANIZATION';
export let ORGANIZATION_CHILD_ORGANIZATION = 'ORGANIZATION_CHILD_ORGANIZATION';
export let ORGANIZATION_ADD_USER = 'ORGANIZATION_ADD_USER';

export let INTERAL_USER_ROUTE = '/admin/user';
export let EXTERNAL_USER_ROUTE = '/admin/user/external';
export let USER_GIVEN_NAME = 'USER_FORM_GIVEN_NAME';
export let USER_MIDDLE_NAME = 'USER_MIDDLE_NAME';
export let USER_FAMILY_NAME = 'USER_FAMILY_NAME';
export let USER_USER_NAME = 'USER_USER_NAME';
export let USER_EMAIL = 'USER_EMAIL';
export let USER_PASSWORD = 'USER_PASSWORD';
export let USER_PASSWORD_CONFIRM = 'USER_PASSWORD_CONFIRM';
export let USER_ORGANIZATION_ID = 'USER_ORGANIZATION_ID';
export let USER_ROLE_ID = 'USER_ROLE_ID';
export let USER_STATUS_ID = 'USER_STATUS_ID';
export let USER_STATUS_OPTIONS = [
    {
        value: 0,
        label: 'Active'
    },
    {
        value: 1,
        label: 'Locked'
    },
    {
        value: 2,
        label: 'Password Expired'
    },
    {
        value: 3,
        label: 'Inactive'
    },
    {
        value: 4,
        label: 'Deleted'
    }
];

export let ASPECT_RATIO_16_9: number = 16 / 9; // 1.7777777777
export let ASPECT_RATIO_16_10: number = 16 / 10; // 1.6
export let ASPECT_RATIO_3_2: number = 3 / 2; // 1.5
export let ASPECT_RATIO_22_15: number = 22 / 15; // 1.4666666667 
export let ASPECT_RATIO_4_3: number = 4 / 3; // 1.3333333333