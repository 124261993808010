// libraries
import * as React from 'react';
import { CallbackComponent } from 'redux-oidc';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
// components
import RedirectContent from './RedirectContent';
// store
import { AppState } from '../../store';
import { User } from 'oidc-client';
import userManager from '../../utils/userManager';

interface StateProps {
    user: User | undefined;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user
    };
}

type OidcCallbackProps = StateProps & RouteComponentProps<any>;

class OidcCallback extends React.Component<OidcCallbackProps> {
    constructor(props: Readonly<OidcCallbackProps>) {
        super(props);
    }

    successCallback = (user: User): void => {
        window.location.replace(user.state ? user.state.path : '/');
    };

    errorCallback = (error: Error): void => {
        console.error('There was an error handling the token callback: ' + error.message);
    };

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={this.successCallback}
                errorCallback={this.errorCallback}
            >
                <RedirectContent />
        </CallbackComponent>
        );
    }
}

export default connect(
    mapStateToProps, 
)(withRouter(OidcCallback));