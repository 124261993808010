import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import userManager from '../../../utils/userManager';
import { unloadUserProfile } from '../../../store/profile/actions';
import { ProfileState } from '../../../store/profile/types';
import { AppState } from '../../../store';
import { User } from 'oidc-client';

declare var __AUTHORITY_URL__: string;
declare var __CLIENT_URL__: string;

interface StateProps {
    user: User | undefined;
    profile: ProfileState;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user,
        profile: state.profile
    };
}

interface DispatchProps {
    unloadUserProfile: typeof unloadUserProfile;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        unloadUserProfile: unloadUserProfile
    }, dispatch);
}

type AuthenticationButtonProps = StateProps & DispatchProps & RouteComponentProps<any>;

interface AuthenticationButtonState {
    isLoading: boolean;
};

class AuthenticationButton extends React.Component<AuthenticationButtonProps, AuthenticationButtonState> {
    constructor(props: Readonly<AuthenticationButtonProps>) {
        super(props);

        this.state = {
            isLoading: false
        } as AuthenticationButtonState;
    }

    signIn = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        userManager.signinRedirect();
    }

    signOut = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();

        userManager.getUser()
            .then((user) => {
                this.props.unloadUserProfile();

                window.sessionStorage.removeItem(`oidc.user:${__AUTHORITY_URL__}:algovideo`);

                window.location.replace(
                    `${__AUTHORITY_URL__}/connect/endsession?id_token_hint=${user?.id_token}` + 
                    `&post_logout_redirect_uri=${__CLIENT_URL__}/session_ended`
                );
            });
    }

    render() {
        const { user, location } = this.props;

        if (user && !user.expired) {
            return (
                <div className={`btn-group my-2 my-sm-0 mr-lg-0 mr-sm-0`}>
                    <div className="btn-group dropdown" role="group">
                        <button 
                            className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                            type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        >
                            <span className="sr-only">Toggle user menu</span>
                        </button>
                        <div className="dropdown-menu">
                            <button className={`dropdown-item`} onClick={this.signOut}>Sign Out</button>
                        </div>
                    </div>
                    <button type="button" className={`av-auth-btn av-auth-signout-btn`}>
                        <span className={`av-auth-btn-label`}></span> {user.profile.preferred_username}
                    </button>
                </div>
            );
        }
        else if (location.pathname !== '/callback') {

            let btnClass: string = 
                `av-auth-btn av-auth-signin-btn my-2 my-sm-0 mr-lg-0 mr-sm-0` +
                `${this.state.isLoading ? 'loading' : '' }`;

            return (
                <button 
                    className={btnClass} 
                    onClick={this.signIn}
                >
                    <span className='av-auth-btn-label'></span> 
                    Sign In
                </button>
            );
        }
        else {
            return null;
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AuthenticationButton));