import { leftArrow } from '../images';
import { rightArrow } from '../images';
import { searchIcon } from '../images';
//pages
export const CAMERA_MANAGEMENT = {
    //components
    SELECTION_ROW: {
        //images
        LEFT_ARROW: {
            src: leftArrow,
            alt: "double left-facing arrows",
            width: 48,
            height: 48,
        },
        RIGHT_ARROW: {
            src: rightArrow,
            alt: "double right-facing arrows",
            width: 48,
            height: 48,
        }
    },
    DUMMY_SEARCH: {
        SEARCH_ICON: {
            src: searchIcon,
            alt: "a small magnifying glass",
            width: 16,
            height: 16,
        }
    }
}