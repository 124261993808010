import * as React from 'react';

interface SearchBoxProps {
    disabled: boolean;
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void);
}

export default class SearchBox extends React.Component<SearchBoxProps> {
    constructor(props: Readonly<SearchBoxProps>) {
        super(props);
    }

    render() {
        const { disabled, onChange } = this.props;
        return (
            <div className='av-section'>
                <label htmlFor='txtQuery' className='form-label'>Search</label>
                <input 
                    className='av-txt-input' type='search' autoComplete='off' 
                    placeholder='Search...' disabled={disabled === true} onChange={onChange} 
                />
            </div>
        );
    }
}