// libraries
import * as React from 'react';
import { withRouter, NavLink, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
// models & interfaces
import { VIEW_VIDEOBOARD, VIEW_CAMERA } from '../../../models/Privileges';
import { 
    FIRST_RESPONDER_CAMERA_VIEWER, 
    MEDIA_CAMERA_VIEWER, 
    PRIVATE_SECTOR_CAMERA_VIEWER
} from '../../../models/Roles';
// components
import AuthenticationButton from './AuthenticationButton';
// store
import { AppState } from '../../../store';
import { ProfileState } from '../../../store/profile/types';
import { User } from 'oidc-client';
// resources
import { algoLogo } from '../../../images';

interface OwnProps {

}

interface StateProps {
    user: User | undefined;
    profile: ProfileState;
}

let mapStateToProps = (store: AppState): StateProps => {
    return {
        user: store.oidc.user,
        profile: store.profile
    };
}

interface DispatchProps {

}

type HeaderProps = 
    OwnProps & 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

declare var __ADMIN_URL__: string;

class Header extends React.Component<HeaderProps, {}> {

    

    render() {
        let { user, profile } = this.props;

        // validations
        let isNonExpiredUser: boolean = (user !== null && user !== undefined &&  !user.expired);
        let userHasRoles: boolean = ( 
            profile !== null && 
            profile !== undefined && 
            profile.userProfile.role !== null &&
            profile.userProfile.role !== undefined && 
            profile.userProfile.role.length > 0
        );

        let dashboardLink = (user && !user.expired && profile.initialized) && profile.userProfile.hasPrivilege(VIEW_VIDEOBOARD) ? (
            <li className='nav-item' key={Math.random()}>
                <NavLink exact to={'/'} className='av-nav-link' activeClassName='active'>
                    <i className='fa fa-road'></i> Dashboard
                </NavLink>
            </li>
        ) : (null);

        let camerasLink = (user && !user.expired && profile.initialized) && profile.userProfile.hasPrivilege(VIEW_CAMERA) ? (
            <li className='nav-item' key={Math.random()}>
                <NavLink to={'/cameras'} className='av-nav-link' activeClassName='active'>
                    <i className='fas fa-video'></i> Cameras
                </NavLink>
            </li>
        ) : (null);

        let adminLink = (
            ( isNonExpiredUser && profile.initialized ) && 
            userHasRoles && 
            !(
                profile.userProfile.isInRole(MEDIA_CAMERA_VIEWER) || 
                profile.userProfile.isInRole(FIRST_RESPONDER_CAMERA_VIEWER) || 
                profile.userProfile.isInRole(PRIVATE_SECTOR_CAMERA_VIEWER)
            ) 
        ) ? (
            <li className='nav-item' key={Math.random()}>
                <a href={__ADMIN_URL__} className='av-nav-link' target='_blank'>
                    <i className='fa fa-cog'></i> Administration
                </a>
            </li>
        ) : (null);

        let guideLink = (user && !user.expired && profile.initialized) ? (
            <li className='nav-item' key={Math.random()}>
                <NavLink to={'/guide'} className='av-nav-link' activeClassName='active'>
                    <i className='fas fa-question-circle'></i> Help
                </NavLink>
            </li>
        ): (null);

        let loadingLinks = (
            <li className='nav-item loading' key={Math.random()}>
                <i className="fas fa-circle-notch fa-spin"></i> Loading...
            </li>
        );

        const links = <ul className='av-nav-section'>{!profile.initialized ? loadingLinks : [dashboardLink, camerasLink, adminLink, guideLink]}</ul>;

        return (
            <header className='av-navbar'>
                <NavLink className='navbar-brand ml-lg-0 ml-sm-0' to={'/'}>
                    <img src={algoLogo.default} className='av-logo' />
                </NavLink>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className='collapse navbar-collapse' id='navbarContent'>
                    {links}
                    <AuthenticationButton />
                </div>
            </header>
            );
    }
}

export default connect(
    mapStateToProps
)(withRouter(Header));