export let CAMERA_ADMINISTRATOR = 'CameraAdministrator';
export let VIEW_VIDEOBOARD = 'ViewVideoboard';
export let CREATE_VIDEOBOARD = 'CreateVideoboard';
export let EDIT_VIDEOBOARD = 'EditVideoboard';
export let DELETE_VIDEOBOARD = 'DeleteVideoboard';
export let VIEW_CAMERA = 'ViewCamera';
export let EDIT_CAMERA = 'EditCamera';
export let SYSTEM_ADMINISTRATOR = 'SystemAdministrator';
export let CREATE_USER = 'CreateUser';
export let EDIT_USER = 'EditUser';
export let RESET_USER_PASSWORD = 'ResetUserPassword';
export let MEDIA_STREAM_ACCESS = 'MediaStreamAccess';
export let FIRST_RESPONDER_STREAM_ACCESS = 'FirstResponderStreamAccess';
export let PRIVATE_SECTOR_STREAM_ACCESS = 'PrivateSectorLimitedStreamAccess';
export let INTERNAL_STREAM_ACCESS = 'InternalStreamAccess';
export let UNLIMITED_STREAMING = 'UnlimitedStreaming';