// libraries
import * as React from 'react';
import { processSilentRenew } from 'redux-oidc';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
// store
import { AppState } from '../../store';
import { User } from 'oidc-client';

interface StateProps {
    user: User | undefined;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user
    };
}

type OidcSilentRenewProps =  StateProps & RouteComponentProps<any>;

class OidcSilentRenew extends React.Component<OidcSilentRenewProps> {
    constructor(props: Readonly<OidcSilentRenewProps>) {
        super(props);
    }

    componentDidMount() {
        processSilentRenew();
    }

    render() {
        return <div>Renewing token...</div>;
    }
}

export default connect(
    mapStateToProps, 
)(withRouter(OidcSilentRenew));