// libraries
import * as React from 'react';
// components
import Content from '../base/layout/Content';

export default class AccessDenied extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        <div className='av-message-content'>
                            <h1 className='sr-only'>
                                Access Denied
                            </h1>
                            <div className='message-container'>
                                <div className='av-center-alert-container'>
                                    <div className='av-danger-alert'>
                                        You do not have access to this page
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}