import userManager from "../userManager";
import "isomorphic-fetch";

export function getAccessToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        userManager.getUser()
            .then(user => {
                if (user) {
                    resolve(user.access_token);
                }
                else {
                    reject('Access denied due to no user credential');
                }
            }, reason => {
                reject(reason);
            })
            .catch(error => {
                throw error;
            });
    });
}

// export function getAccessTokenThen(manager: any, actionFun: any, dispatch: any, thenFun: any, rejectFun: (e: Error) => any, catchFun: (e: Error) => any) {
//     getAccessToken().then(
//         (token) => {
//             manager.setAccessToken(token);
//             actionFun()
//                 .then((response: any) => {
//                     dispatch(thenFun(response));
//                 }, (reject: any) => {
//                     dispatch(rejectFun(new Error(reject)));
//                 })
//                 .catch((error: any) => {
//                     dispatch(catchFun(new Error(error)));
//                 });
//         }
//     )
// }