// libraries
import * as React from "react";

export type IErrorProps = {
    //
}

export const Error: React.FC<IErrorProps> = (props) => {

    return(
        <div className='av-fatal-error-container'>
            <div className='av-error-content'>
                <h1>Something went wrong</h1>
                <p>Please refresh the page. If problems persist, please contact the system administrator.</p>
                <div>
                <button className={`btn btn-primary`} 
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();

                        window.history.go();
                    }}>Reload page</button>
                </div>
            </div>
        </div>
    );
};

export default Error;