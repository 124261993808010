// libraries
import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from 'redux';
// interfaces & models
// enums
import { EATRegion } from '@algo/network-manager/models/v3';
// components
import {ThemedDropdown} from "@caps-mobile/themed-dropdown";
// store
import { AppState } from '../../store';
import { ProfileState } from '../../store/profile/types';
import { DatasourceState } from '../../store/datasource/types';
import { User } from 'oidc-client';
import { loadDatasources } from '../../store/datasource/actions';

interface OwnProps {
    onChangeCallback: (region: EATRegion) => void;
    disabled: boolean;
}

interface StateProps {
    user: User | undefined;
    profile: ProfileState;
    datasource: DatasourceState;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user,
        profile: state.profile,
        datasource: state.datasource
    };
}

interface DispatchProps {
    loadDatasources: typeof loadDatasources;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loadDatasources: loadDatasources
    }, dispatch);
}

type DatasourceSelectorProps = OwnProps & StateProps & DispatchProps;

interface DatasourceSelectorState {
    selectedValue: EATRegion;
    selectedIndex: number | null;
    disabled: boolean;
}

class DatasourceSelector extends React.Component<DatasourceSelectorProps, DatasourceSelectorState> {
    constructor(props: Readonly<DatasourceSelectorProps>) {
        super(props);

        this.state = {
            selectedValue: EATRegion.Unknown,
            selectedIndex: null,
            disabled: false
        } as DatasourceSelectorState;
    }

    componentDidMount() {
        this.props.loadDatasources();
        this.forceUpdate();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        let { onChangeCallback } = this.props;

        if (
            this.props.datasource.datasources.length === 1 && 
            this.state.selectedValue === EATRegion.Unknown
        ) {
            onChangeCallback(this.props.datasource.datasources[0].region);

            this.setState((state, props) => {
                return {
                    ...state,
                    selectedValue: props.datasource.datasources[0].region,
                    disabled: true
                };
            });
        }
    }

    datasourceSelected = (selectedIndex: number) => {
        let { datasource, onChangeCallback } = this.props;

        let dId = selectedIndex === 0 
            ? EATRegion.ALDOT
            : datasource.datasources[selectedIndex - 1].region;

        this.setState((state, props) => {
            return {
                ...state,
                selectedValue: dId,
                selectedIndex: selectedIndex
            }
        });

        onChangeCallback(dId);
    };

    render() {
        let { datasource  } = this.props;

        let listItems: string[] = (datasource.datasources.length > 0) 
            ?   datasource.datasources.map(
                    (item, index) => { return item.displayName;}
                )
            :   [];

        listItems = ["All", ...listItems];

        return (
            <div className='av-section'>
                <label 
                    htmlFor='ddlDatasource' 
                    className={`form-label ${this.props.disabled ? 'loading' : ''}`}
                >
                    Region
                </label>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <ThemedDropdown 
                        height={30} placeholder={"Select"} 
                        options={listItems} noDropBorder={false}
                        selectedIndex={this.state.selectedIndex}
                        menuAttached={false} 
                        callback={this.datasourceSelected}
                        searchable={false} scrollThreshold={7}
                        disabled={this.props.disabled || (this.state.disabled)}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(DatasourceSelector);