import { 
    ProfileState, ProfileTypes, CLEAR_PROFILE_ERROR, 
    UNLOAD_PROFILE, FETCH_PROFILE_BEGIN, FETCH_PROFILE_SUCCESS, 
    FETCH_PROFILE_FAILURE 
} from "./types";
import { AAUserAuthorizationProfile } from '@algo/network-manager/models/v3/admin';
import { ProcessedResponse } from "@algo/network-manager/managers/v3";

let initialState: ProfileState = {
    userProfile: new AAUserAuthorizationProfile(),
    loading: false,
    initialized: false,
    error: null,
    lastResponse: new ProcessedResponse()
}

export function profileReducer(state = initialState, action: ProfileTypes): ProfileState {
    switch(action.type) {
        case FETCH_PROFILE_BEGIN:
            return {
                ...state,
                loading: true
            };
        case FETCH_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                initialized: true,
                userProfile: new AAUserAuthorizationProfile(action.payload.response.data),
                lastResponse: action.payload.response
            };
        case FETCH_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case CLEAR_PROFILE_ERROR:
            return {
                ...state,
                error: null
            };
        case UNLOAD_PROFILE:
            return initialState;
        default:
            return state;
    }
}