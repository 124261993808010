// libraries
import * as React from 'react';
import { connect } from "react-redux";
// models & interfaces
import { IATCamera } from '@algo/network-manager/models/v3';
// store
import { AppState } from '../../../store';
import { ProfileState } from '../../../store/profile/types';
import { User } from 'oidc-client';
// constants
import { ASPECT_RATIO_16_10 } from '../../../utils/AppConstants';

interface OwnProps {
    item: IATCamera;
    className?: string;
    isAdminView: boolean;
    isAvailable: boolean;
    viewLivestreamClick: (item: IATCamera) => void;
}

interface StateProps {
    user: User | undefined;
    profile: ProfileState;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user,
        profile: state.profile
    };
};

type CameraProps = OwnProps & StateProps;

interface CameraState {
    viewingLivestream: boolean;
    isFullscreen: boolean;
    imageUrl: string;
    loadingImage: boolean;
    snapshotAspectRatioClass: string;
}

class Camera extends React.Component<CameraProps, CameraState> {
    /*
     * SNAPSHOT REF
     *
     * This tracks the instance of the snapshot <img> in DOM 
     * for an instance of the Camera Component
     * 
     */
    snapshotRef: React.RefObject<HTMLImageElement>;
    
    constructor(props: Readonly<CameraProps>) {
        super(props);
        /*
         * CREATE SNAPSHOT REF
         * 
         * This creates the ref instance used 
         * by this component instance
         * 
         */ 
        this.snapshotRef = React.createRef();

        this.state = {
            imageUrl: '',
            loadingImage: true,
            snapshotAspectRatioClass: 'fill'
        } as CameraState;
    }

    componentDidMount() {
        /*
         * SNAPSHOT IMAGE LOAD EVENT LISTENER
         * 
         * Adding an event listener to fire off and event
         * when the image has loaded
         */ 
        this.snapshotRef.current &&
            this.snapshotRef.current.addEventListener('load', this.snapshotImageLoaded);
    }

    componentWillUnmount() {
        /*
         * REMOVE SNAPSHOT IMAGE LOAD EVENT LISTENER
         * 
         * Trying to avoid memory leaks.
         * 
         */ 

        this.snapshotRef.current &&
            this.snapshotRef.current.removeEventListener('load', this.snapshotImageLoaded);
    }

    /*
     * SNAPSHOT IMAGE LOADED EVENT
     * 
     * When the snapshot image is loaded this event is fired.
     * 
     * If the natural aspect ratio is 16:10 then set to object-fit to contain;
     * otherwise set object-fit to fill.
     * 
     */ 
    snapshotImageLoaded = (event: Event): void => {
        let snapshot = this.snapshotRef.current;

        if (snapshot) {

            let naturalAspectRatio: number = snapshot.naturalWidth / snapshot.naturalHeight;
            let aspectRatioClass: string = 'fill';

            if (naturalAspectRatio === ASPECT_RATIO_16_10)
                aspectRatioClass = 'contain';

            this.setState({
                ...this.state,
                snapshotAspectRatioClass: aspectRatioClass
            });
        }
        
    }

    reloadPage = (): void => {
        window.location.reload();
    }

    render() {
        const { item, className } = this.props;

        return (
            <div className='av-camera-container' id="camera-container">
                <div className={`av-camera ${className ? className : ''}`}>
                    <div className='av-camera-content'>
                        <div className="card av-camera-card">
                            <img 
                                ref={this.snapshotRef} 
                                src={item.imageUrl} 
                                className={`av-camera-img ${this.state.snapshotAspectRatioClass}`} 
                                alt={`Camera snapshot of: ${item.location.routeDesignator} & ${item.location.crossStreet}`} 
                            />
                            <div className="card-body p-3">
                                <div className="av-camera-header">
                                    <div className="text-center av-camera-title">
                                        {item.location.routeDesignator}
                                    </div>
                                    <div className="text-center text-muted av-camera-subtitle">
                                        {item.location.crossStreet}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button
                                        disabled={!this.props.isAvailable}
                                        className='av-outline-btn block av-livestream-btn'
                                        data-toggle='modal' data-target={`#${item.id}_livestreamModal`}
                                        onClick={() => { this.props.viewLivestreamClick(item) }}
                                    >
                                        <i className='fa fa-eye'></i> View Livestream
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps
)(Camera);