// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
// components
import RedirectContent from './RedirectContent';
// store
import { AppState } from "../../store";
import { User } from "oidc-client";

interface StateProps {
    user: User | undefined;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user
    };
}

type RedirectProps = StateProps & RouteComponentProps<any>;

const Redirect: React.FC<RedirectProps> = (props) => {
    return (
        <div>
            <div className='av-flex-container'>
                <RedirectContent />
            </div>
        </div>            
    );
}

export default connect(
    mapStateToProps, 
)(withRouter(Redirect));