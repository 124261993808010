// libraries
import * as React from 'react';

export default class Sidebar extends React.Component<{}, {}> {
    render() {
        return (
            <nav className='av-sidebar'>
                <div id="sidebarHeader" className='av-section av-section-header'>
                    <h2>
                        Tools 
                    </h2>
                </div>
                <div id='sidebarActions' className={`av-sidebar-actions collapse show`}>
                    {this.props.children}
                </div>
            </nav>
        );
    }
}