// libraries
import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import fscreen from 'fscreen';
// interfaces & models
import { IAVLegacyVideoBoard } from "@algo/network-manager/models/v3/video";
import { IATCamera } from '@algo/network-manager/models/v3';
// components
import Stream from './Stream';
import {Pager} from "@algo/pager";
// store
import { savePageState } from '../../../store/videoboard/actions';
import { User } from 'oidc-client';
import { CameraState } from '../../../store/camera/types';
import { AppState } from '../../../store';
import { ProfileState } from '../../../store/profile/types';
// constants
import { STREAM_TIMEOUT_INTERVAL } from '../../../utils/AppConstants';
import { DEFAULT_PAGE_INDEX } from '../../../utils/AppConstants';
import { UNLIMITED_STREAMING } from '../../../models/Privileges';

interface OwnProps {
    board: IAVLegacyVideoBoard;
    currentPageIndex: number;
}

interface StateProps {
    user: User | undefined;
    camera: CameraState;
    profile: ProfileState;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user,
        camera: state.camera,
        profile: state.profile
    };
}

interface DispatchProps {
    savePageState: typeof savePageState;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        savePageState: savePageState
    }, dispatch);
}

type VideoboardProps = OwnProps & StateProps & DispatchProps;

interface VideoboardState {
    currentPageIndex: number;
    pageTimer: number;
    isFullscreen: boolean;
    isPaused: boolean;
}

class Videoboard extends React.Component<VideoboardProps, VideoboardState> {
    pageRefresher: NodeJS.Timeout | null;
    pageTransitionInterval: NodeJS.Timeout | null;

    videoboardRef: React.RefObject<HTMLDivElement>;

    constructor(props: Readonly<VideoboardProps>) {
        super(props);

        this.videoboardRef = React.createRef();
        this.pageRefresher = null;
        this.pageTransitionInterval = null;

        if (!props.profile.userProfile.hasPrivilege(UNLIMITED_STREAMING)) {
            this.pageRefresher = setInterval(this.reloadPage, STREAM_TIMEOUT_INTERVAL);
        }

        fscreen.addEventListener('fullscreenchange', this.fullscreenEventAction);
        window.addEventListener('keydown', this.keyListener);

        this.state = {
            currentPageIndex: props.currentPageIndex ? props.currentPageIndex : DEFAULT_PAGE_INDEX,
            pageTimer: 60,
            isFullscreen: false,
            isPaused: false
        } as VideoboardState;
    }

    componentDidMount() {
        ($('[data-toggle="tooltip"]') as any).tooltip();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        ($('[data-toggle="tooltip"]') as any).tooltip();

        if (this.state.currentPageIndex !== prevState.currentPageIndex) {
            this.props.savePageState(this.state.currentPageIndex);
        }

        if (
            this.state.isFullscreen && 
            (prevState.isFullscreen !== this.state.isFullscreen)
        ) {
            this.videoboardRef.current && 
                fscreen.requestFullscreen(this.videoboardRef.current);
        }
    }

    componentWillUnmount() {
        fscreen.removeEventListener('fullscreenchange', this.fullscreenEventAction);

        window.removeEventListener('keydown', this.keyListener);

        if (!this.props.profile.userProfile.hasPrivilege(UNLIMITED_STREAMING)) {
            this.pageRefresher &&
                clearInterval(this.pageRefresher);
        }

        this.destroyPageTransition();
    }

    reloadPage = (): void => {
        window.location.reload();
    }

    fullscreenEventAction = (): void => {
        if (fscreen.fullscreenElement === null) {
            this.destroyPageTransition();

            this.setState({
                isFullscreen: false,
                isPaused: false
            });
        }
        else if (fscreen.fullscreenElement?.classList.contains('av-stream-player')) {
            if (!this.state.isPaused) {
                this.destroyPageTransition();
            }
        }
        else if (
            fscreen.fullscreenElement?.classList.contains('av-videoboard') && 
            typeof this.pageTransitionInterval !== 'function'
        ) {
            if (!this.state.isPaused) {
                this.createPageTransition();
            }
        }
    }

    toggleFullscreen = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (!this.state.isFullscreen) {
            this.setState((prevState, props) => {
                return {
                    isFullscreen: true
                };
            });
        }
        else {
            fscreen.exitFullscreen();

            this.setState((prevState, props) => {
                return {
                    isFullscreen: false
                };
            });
        }
    }

    keyListener = (event: KeyboardEvent): void => {
        if (
            (this.props.board.videoPages.length > 1) &&
            this.state.isFullscreen && 
            (event.keyCode === 37 || event.keyCode === 39)
        ) {
            
            if (!this.state.isPaused) {
                this.destroyPageTransition();
            }

            this.setState((prevState, props) => {
                if (event.keyCode === 37) {
                    return {
                        currentPageIndex: prevState.currentPageIndex === 0 
                            ? (props.board.videoPages.length - 1) 
                            : (prevState.currentPageIndex - 1)
                    };
                }
                else if (event.keyCode === 39) {
                    return {
                        currentPageIndex: 
                            (prevState.currentPageIndex === (props.board.videoPages.length - 1)) 
                                ? 0 
                                : (prevState.currentPageIndex + 1)
                    };
                }
                else{
                    return {
                        currentPageIndex: this.state.currentPageIndex
                    }
                }
            });

            if (!this.state.isPaused) {
                this.createPageTransition();
            }
        }

        else if (this.props.board.videoPages.length > 1 && this.state.isFullscreen && event.keyCode === 32) {
            if (this.state.isPaused) {
                this.createPageTransition();
            }
            else {
                this.destroyPageTransition();
            }

            this.setState((prevState, props) => {
                return {
                    isPaused: !prevState.isPaused
                };
            });
        }
    }
        
    createPageTransition = (): void => {
        this.pageTransitionInterval =
            setInterval(this.pageTransitionHandler, this.state.pageTimer * 1000);
    }

    destroyPageTransition = (): void => {
        this.pageTransitionInterval &&
            clearInterval(this.pageTransitionInterval);
    }

    pageTransitionHandler = (): void => {
        this.setState((state, props) => {
            return {
                currentPageIndex: ( props.board.videoPages.length === (state.currentPageIndex + 1) ) 
                    ? 0 
                    : state.currentPageIndex + 1
            };
        });
    }

    updatePageIndex = (newPageIndex: number) => {
        this.setState({
            ...this.state,
            currentPageIndex: newPageIndex,
        });
    }

    changePageTimer = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();


        if (event.currentTarget.value !== '') {
            this.setState({
                pageTimer: parseInt(event.currentTarget.value)
            });
        }
    }

    renderPage = (
        board: IAVLegacyVideoBoard, 
        currentPageIndex: number, 
        cameras: IATCamera[]
    ): React.ReactNode => {
        let page = board.videoPages[currentPageIndex];

        if (!page) {
            return (
                <div className='av-center-alert-container'>
                    <div className='av-primary-alert'>
                        No page available to view
                    </div>
                </div>
            );
        }
        else {
            let colClass: string = `av-grid-col-${page.columnSize}`;
            let rowClass: string = `av-grid-row-${page.rowSize}`;

            return (
                <div className={`av-stream-display ${colClass} ${rowClass}`}>
                    {page.videoItems.map((videoItem: any, index: any) => {
                        const cameraItem = cameras.find((item) => { return item.id === videoItem.cameraId; });

                        if (cameraItem) {
                            return ( 
                                <Stream 
                                    key={`${videoItem.id}_${videoItem.placement}_${cameraItem.id}`} 
                                    className='' item={cameraItem} autoplay={true} 
                                />
                            )
                        }
                        else {
                            return (
                                <Stream key={Math.random()} className='' item={null} autoplay={false} />
                            );
                        }
                    })}
                </div>
            );
        }
    }

    render() {
        const { board, camera } = this.props;

        return (
            <div 
                id={`videoboard_${board.id}`} key={`videoboard_${board.id}`} 
                className='av-videoboard' ref={this.videoboardRef}
            >
                <h2 className='av-videoboard-title'>{board.name}</h2>

                <div className='av-videoboard-toolbar'>
                    <div className='d-flex flex-row no-gutters'>
                        <div className='col-2'>
                            <div 
                                className='av-page-timer-group' data-toggle='tooltip' 
                                data-html='true' title='Page timer <strong>(in seconds)</strong>'
                            >
                                <label className='sr-only'>Page timer (in seconds)</label>
                                <input 
                                    className='av-page-timer' id='txtPageTimer' type='number' 
                                    defaultValue={this.state.pageTimer.toString()} 
                                    onChange={this.changePageTimer} 
                                />
                                <div className='input-group-append'>
                                    <span className='av-page-timer-append-text'>
                                        <i className='fas fa-clock'></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='av-videoboard-toolbar-title'>
                            {board.name}
                        </div>
                        <div className='col-2 text-right'>
                            <button
                                className='av-fullscreen-btn'
                                onClick={this.toggleFullscreen}
                                data-toggle='tooltip'
                                title='Start fullscreen mode'
                                disabled={board.videoPages.length === 0}
                            >
                                <i className='fa fa-play'></i>
                                <span className='sr-only'>
                                    Go Fullscreen
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                {this.renderPage(board, this.state.currentPageIndex, camera.items)}
                <div style={
                    {
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center", 
                        padding: "8px 0 0 0"
                    }}
                >
                    <Pager 
                        pageIndex={this.state.currentPageIndex} 
                        pageCount={board.videoPages.length} 
                        updatePageIndex={this.updatePageIndex}
                        minimalNav={true} size={"xs"}
                        llipsisThreshold={5} ellipsisOffset={1}
                    />
                </div>
            </div>
            );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(Videoboard);