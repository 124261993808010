//types
import { ProcessedResponse } from "@algo/network-manager/managers/v3";
import { ATCamera, IATCameraDto } from "@algo/network-manager/models/v3";
import {
    CameraState, CameraTypes,
    FETCH_CAMERAS_BEGIN, FETCH_CAMERAS_SUCCESS, FETCH_CAMERAS_FAILURE, FETCH_CAMERAS_NOCHANGE,
    UPDATE_ACCESS_LEVEL_BEGIN, UPDATE_ACCESS_LEVEL_SUCCESS, UPDATE_ACCESS_LEVEL_FAILURE, UPDATE_ACCESS_LEVEL_CLEAR,
    FILTER_DATASOURCES_CAMERA_BEGIN, FILTER_DATASOURCES_CAMERA_SUCCESS, FILTER_DATASOURCES_CAMERA_FAILURE,
    MODIFY_CAMERA_INFO_BEGIN, MODIFY_CAMERA_INFO_SUCCESS, MODIFY_CAMERA_INFO_FAILURE,
    BULK_UPDATE_BEGIN, BULK_UPDATE_SUCCESS, BULK_UPDATE_FAILURE,
    FILTER_EDITOR_CAMERA_FAILURE, FILTER_EDITOR_CAMERA_SUCCESS, FILTER_EDITOR_CAMERA_BEGIN,
    REFRESH_CAMERA_LISTS_FAILURE, REFRESH_CAMERA_LISTS_SUCCESS, REFRESH_CAMERA_LISTS_BEGIN,
    BULK_UPDATE_CLEAR, CLEAR_CAMERA_ERROR
} from "./types";

let initialState: CameraState = {
    items: [],
    checksum: "",
    count: "",
    mgmtFilteredItems: null,
    editorFilteredItems: [],
    loading: false,
    error: null,
    processing: false,
    saveSuccess: false,
    lastResponse: new ProcessedResponse()
};

export function cameraReducer(state = initialState, action: CameraTypes): CameraState {
    switch (action.type) {
        case FETCH_CAMERAS_BEGIN:
            return {
                ...state,
                loading: true
            };
        case FETCH_CAMERAS_SUCCESS:
            return {
                ...state,
                items: action.payload.response?.data.map( (camera: IATCameraDto) => new ATCamera(camera)),
                checksum: action.payload.response.getChecksum(),
                count: action.payload.response.getCount().toString(),
                error: action.payload.response?.error,
                loading: false,
                lastResponse: action.payload.response
            };
        case FETCH_CAMERAS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case FETCH_CAMERAS_NOCHANGE:
            return {
                ...state,
                loading: false
            };
        case UPDATE_ACCESS_LEVEL_BEGIN:
            return {
                ...state,
                processing: true,
                saveSuccess: false
            };
        case UPDATE_ACCESS_LEVEL_SUCCESS:
            return {
                ...state,
                processing: false,
                saveSuccess: true
            };
        case UPDATE_ACCESS_LEVEL_FAILURE:
            return {
                ...state,
                processing: false,
                saveSuccess: false,
                error: action.payload.error
            };
        case UPDATE_ACCESS_LEVEL_CLEAR:
            return {
                ...state,
                saveSuccess: false
            };
        case FILTER_DATASOURCES_CAMERA_BEGIN:
            return {
                ...state,
                loading: true
            };
        case FILTER_DATASOURCES_CAMERA_SUCCESS:
            return {
                ...state,
                loading: false,
                mgmtFilteredItems: action.payload.mgmtFilteredItems
            };
        case FILTER_DATASOURCES_CAMERA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case MODIFY_CAMERA_INFO_BEGIN:
            return state;
        case MODIFY_CAMERA_INFO_SUCCESS:
            return {
                ...state,
                items: action.payload.items
            };
        case MODIFY_CAMERA_INFO_FAILURE:
            return state;
        case BULK_UPDATE_BEGIN:
            return {
                ...state,
                processing: true
            };
        case BULK_UPDATE_SUCCESS:
            return {
                ...state,
                processing: false,
                saveSuccess: true
            };
        case BULK_UPDATE_FAILURE:
            return {
                ...state,
                processing: false,
                saveSuccess: true,
                error: action.payload.error
            };
        case BULK_UPDATE_CLEAR:
            return {
                ...state,
                saveSuccess: false
            };
        case REFRESH_CAMERA_LISTS_BEGIN:
            return {
                ...state,
                loading: true
            };
        case REFRESH_CAMERA_LISTS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.response?.data.map( (camera: IATCameraDto) => new ATCamera(camera)),
                mgmtFilteredItems: action.payload.mgmtFilteredItems,
                lastResponse: action.payload.response
            };
        case REFRESH_CAMERA_LISTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case CLEAR_CAMERA_ERROR:
            return {
                ...state,
                error: null
            };
        case FILTER_EDITOR_CAMERA_BEGIN:
            return {
                ...state,
                loading: true
            };
        case FILTER_EDITOR_CAMERA_SUCCESS:
            return {
                ...state,
                loading: false,
                editorFilteredItems: action.payload.editorFilteredItems
            };
        case FILTER_EDITOR_CAMERA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}