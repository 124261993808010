import * as React from 'react';

interface AccessLevelLegendModalProps {
    dismissCallback: (() => void);
}

interface AccessLevelLegendModalState {
    instanceId: number;
}

export default class AccessLevelLegendModal extends React.Component<AccessLevelLegendModalProps, AccessLevelLegendModalState> {
    modalRef: React.RefObject<HTMLDivElement>;

    constructor(props: Readonly<AccessLevelLegendModalProps>) {
        super(props);

        this.modalRef = React.createRef();

        this.state = {
            instanceId: Math.random()
        } as AccessLevelLegendModalState;
    }

    componentDidMount() {
        this.modalRef.current && 
            ($(this.modalRef.current) as any).modal('show');
    }

    dismiss = (event: React.MouseEvent<HTMLElement>): void => {
        const { dismissCallback } = this.props;

        event.preventDefault();

        this.modalRef.current &&
            ($(this.modalRef.current) as any).modal('hide');

        dismissCallback();
    }

    render() {

        return (
            <div 
                className='modal fade' ref={this.modalRef} id={`accessLevelLegendModal_${this.state.instanceId}`} 
                tabIndex={-1} role='dialog' data-keyboard='false' data-backdrop='static' 
                aria-labelledby={`AccessLevelLegendModalTitle_${this.state.instanceId}`} aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id={`AccessLevelLegendModalTitle_${this.state.instanceId}`}>
                                Access Level Information
                            </h5>
                        </div>
                        <table className='av-modal-table'>
                            <thead className='thead-light'>
                                <tr>
                                    <th style={{
                                        width: '35%'
                                    }}>
                                        Type
                                    </th>
                                    <th>Who Can See The Camera</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>TMC Operator</td>
                                    <td>ALDOT and TMC users</td>
                                </tr>
                                <tr>
                                    <td>First Responders</td>
                                    <td>ALDOT, TMC, and First Responders users</td>
                                </tr>
                                <tr>
                                    <td>Media</td>
                                    <td>ALDOT, TMC, First Responders, and Media users</td>
                                </tr>
                                <tr>
                                    <td>Private Sector</td>
                                    <td>ALDOT, TMC, First Responders, Media, and Private Sector users</td>
                                </tr>
                                <tr>
                                    <td>Full Access</td>
                                    <td>ALDOT, TMC, First Responders, Media, and Private Sector users <strong>and</strong> the public via ALGO Traffic</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='modal-footer'>
                            <button className='btn btn-dark' onClick={this.dismiss}>
                                <i className='fa fa-times-circle'></i> 
                                Dismiss
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}