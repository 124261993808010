import * as React from 'react';

interface ContentProps {
    pageHasSidebar: boolean;
}

export default class Content extends React.Component<ContentProps, {}> {
    render() {
        let { pageHasSidebar } = this.props;

        return (
            <div className={`av-content ${pageHasSidebar === true ? 'shared' : 'primary'}`}>
                {this.props.children}
            </div>
        );
    }
}