// libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";

// store
import { AppState } from '../../../store';
import { CameraState } from '../../../store/camera/types';
import { 
    setCameraToVideoItem, removeCameraFromVideoItem
} from '../../../store/videoboard/actions';
import { IATCamera } from '@algo/network-manager/models/v3';

interface OwnProps {
    className?: string;
    videoPageLocation: number;
    itemLocation: number;
    cameraId: number;
}

interface StateProps {
    camera: CameraState;
}

let mapStateToProps = (state: AppState) => {
    return {
        camera: state.camera
    };
};

interface DispatchProps {
    setCameraToVideoItem: typeof setCameraToVideoItem;
    removeCameraFromVideoItem: typeof removeCameraFromVideoItem;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        setCameraToVideoItem: setCameraToVideoItem,
        removeCameraFromVideoItem: removeCameraFromVideoItem
    }, dispatch);
}

type DroppableVideoItemProps = OwnProps & StateProps & DispatchProps;

interface DroppableVideoItemState {
    videoPageLocation: number;
    itemLocation: number;
    cameraId: number;
    imageUrl: string;
    loadingImage: boolean;
}

class DroppableVideoItem extends React.Component<DroppableVideoItemProps, DroppableVideoItemState> {
    componentRef: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);

        this.componentRef = React.createRef();

        this.state = {
            videoPageLocation: 0,
            itemLocation: 0,
            cameraId: 0,
            imageUrl: '',
            loadingImage: true
        } as DroppableVideoItemState;
    }

    componentDidMount() {
        this.setState((state, props) => {
            return {
                ...state,
                videoPageLocation: props.videoPageLocation,
                itemLocation: props.itemLocation,
                cameraId: props.cameraId
            };
        });
    }

    dragOverHandler = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();

        event.dataTransfer.dropEffect = 'move';

        if (!this.componentRef.current?.classList.contains('active')) {
            this.componentRef.current?.classList.add('active');
        }
    }

    dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();

        if (this.componentRef.current?.classList.contains('active')) {
            this.componentRef.current.classList.remove('active');
        }
    }

    dropHandler = (event: React.DragEvent<HTMLDivElement>): void => {        
        event.preventDefault();

        if (this.componentRef.current?.classList.contains('active')) {
            this.componentRef.current.classList.remove('active');
        }

        var cId = parseInt(event.dataTransfer.getData("text/plain"));

        this.setState((state) => {
            return {
                ...state,
                cameraId: cId
            };
        });

        this.props.setCameraToVideoItem(this.state.videoPageLocation, this.state.itemLocation, cId);
    }

    removeClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        ($('[data-toggle="tooltip"]') as any).tooltip('hide');

        this.props.removeCameraFromVideoItem(this.state.videoPageLocation, this.state.itemLocation);
    }

    renderContent = (item?: IATCamera | null): React.ReactNode => {
        if (item) {
            return (
                <div
                    className={`preview`}
                    style={{
                        backgroundImage: `url(${item.imageUrl})`,
                    }}
                >
                    <span className='sr-only'>Stream preview</span>
                </div>
            );
        }
        else {
            return (
                <div className={`drop-zone`}>
                    <i className="fas fa-plus-square"></i>
                </div>
            );
        }
    }

    renderRemoveButton = (item?: IATCamera | null): React.ReactNode => {
        if (!item) return null;
        
        return (
            <div className='av-stream-remove'>
                <button
                    className="btn btn-link"
                    type="button"
                    data-toggle='tooltip'
                    title='Remove this camera'
                    onClick={this.removeClickHandler}
                >
                    <i className="fas fa-trash-alt"></i>
                    <span className='sr-only'>
                        Remove this camera
                    </span>
                </button>
            </div>
        );
    }

    render() {
        const { camera, className } = this.props;

        const item = ( this.state.cameraId !== 0 ) 
            ?   camera.items.find(
                    (element) => {
                        return element.id === this.state.cameraId;
                    }
                ) 
            : (null);

        return (
            <div
                ref={this.componentRef}
                className={`av-stream-container droppable ${className ? className : ''}`}
                onDrop={this.dropHandler}
                onDragOver={this.dragOverHandler}
                onDragLeave={this.dragLeaveHandler}>
                <div className='av-stream'>
                    <div className='av-stream-content'>
                        <div className={`av-stream-title ${item ? 'visible' : 'invisible'}`}>
                            <div className='av-primary'>
                                {item ? item.location.displayRouteDesignator : 'Unknown'}
                            </div>
                            <div className='av-secondary'>
                                {item ? item.location.crossStreet : 'Unknown'}
                            </div>
                            {this.renderRemoveButton(item)}
                        </div>
                        <div className='av-droppable-wrapper'>
                            {this.renderContent(item)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(DroppableVideoItem);