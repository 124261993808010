// libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { Action } from "redux";
// interfaces & models
import {
    DatasourceTypes, DatasourcePayload,
    FETCH_DATASOURCES_BEGIN, FETCH_DATASOURCES_SUCCESS, FETCH_DATASOURCES_FAILURE,
    CLEAR_DATASOURCES_ERROR
} from "./types";
import { 
    IProcessedResponse, DatasourceNetworkManager, IDatasourceNetworkManager
} from "@algo/network-manager/managers/v3";
import { 
    ATLegacyDatasource, IATLegacyDatasourceDto 
} from "@algo/network-manager/models/v3";

//constants
import { CUR_API_VERSION, CUR_API_ENPOINTS} from "../api-version-constants";
declare var __API_URL__: string;
const apiUrlDatasource: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENPOINTS(CUR_API_VERSION).datasources}`;

/*
Fetch Datasources
*/
function fetchDatasourcesBegin(): DatasourceTypes {
    return {
        type: FETCH_DATASOURCES_BEGIN,
        payload: {} as DatasourcePayload
    };
}

function fetchDatasourcesSuccess(response: IProcessedResponse): DatasourceTypes {
    return {
        type: FETCH_DATASOURCES_SUCCESS,
        payload: {
            datasources: response.data.map( 
                (datasources: IATLegacyDatasourceDto) => new ATLegacyDatasource(datasources)
            ),
            error: response.error,
            lastResponse: response,
        } as DatasourcePayload
    };
}

function fetchDatasourcesFailure(response: IProcessedResponse): DatasourceTypes {
    return {
        type: FETCH_DATASOURCES_FAILURE,
        payload: {
            error: response.error
        } as DatasourcePayload
    };
}

export let loadDatasources = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().datasource.loading) {
        dispatch(fetchDatasourcesBegin());

        let manager: IDatasourceNetworkManager = 
            new DatasourceNetworkManager(apiUrlDatasource);

        manager.getAll(
            getState().datasource.lastResponse, {}
        ).then(
            (processedResponse: IProcessedResponse) => {
                if (processedResponse.data)
                    dispatch(fetchDatasourcesSuccess(processedResponse));
                else
                    dispatch(fetchDatasourcesFailure(processedResponse));
            }
        )
    }
}

/*
Clear Datasource Error
*/

function clearDatasourceErrorState(): DatasourceTypes {
    return {
        type: CLEAR_DATASOURCES_ERROR,
        payload: {} as DatasourcePayload
    };
}

export let clearDatasourceError = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().datasource.error !== null) {
        dispatch(clearDatasourceErrorState());
    }
}