//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { Action } from "redux";
import { getAccessToken } from "../../utils/data/getAccessToken";
// interfaces & models
import { 
    AVLegacyVideoBoard, IAVLegacyVideoBoard, IAVLegacyVideoBoardDto 
} from "@algo/network-manager/models/v3/video";
import { 
    ICreateLegacyVideoBoard, IDeleteLegacyVideoBoard, 
    IGetByIdLegacyVideoBoard, IProcessedResponse, IUpdateLegacyVideoBoard 
} from "@algo/network-manager/managers/v3";
import {
    LegacyVideoBoardNetworkManager, ILegacyVideoBoardNetworkManager
} from "@algo/network-manager/managers/v3";
import * as TYPES from "./types";

//constants
import { CUR_API_VERSION, CUR_API_ENPOINTS} from "../api-version-constants";
declare var __API_URL__: string;
const apiUrlVideoboard: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENPOINTS(CUR_API_VERSION).legacyVideoboards}`;

    function initializeSuccess(videoboard: IAVLegacyVideoBoard): TYPES.EditorTypes {
        return {
            type: TYPES.INITIALIZE_VIDEOBOARD_SUCCESS,
            payload: {
                videoboard: videoboard
            } as TYPES.EditorPayload
        };
    }

function loadSuccess(response: IProcessedResponse): TYPES.EditorTypes {
    return {
        type: TYPES.LOAD_VIDEOBOARD_SUCCESS,
        payload: { 
            response: response
        } as TYPES.EditorPayload
    };
}

function initializeFailure(error: Error) {
    return {
        type: TYPES.INITIALIZE_VIDEOBOARD_FAILURE,
        payload: {
            error: error
        } as TYPES.EditorPayload
    };
}

/*
 * 
 * SET CAMERA STATE MESSAGES
 * 
 */

function setNameSuccess(videoboard: IAVLegacyVideoBoard): TYPES.EditorTypes {
    return {
        type: TYPES.SET_NAME_SUCCESS,
        payload: {
            videoboard: videoboard
        } as TYPES.EditorPayload
    };
}

function setNameFailure(error: Error): TYPES.EditorTypes {
    return {
        type: TYPES.SET_NAME_FAILURE,
        payload: {
            error: error
        } as TYPES.EditorPayload
    };
}

/*
 * 
 * SET CAMERA STATE MESSAGES
 * 
 */

function setCameraSuccess(videoboard: IAVLegacyVideoBoard): TYPES.EditorTypes {
    return {
        type: TYPES.SET_CAMERA_ITEM_SUCCESS,
        payload: {
            videoboard: videoboard
        } as TYPES.EditorPayload
    };
}

function setCameraFailure(error: Error): TYPES.EditorTypes {
    return {
        type: TYPES.SET_CAMERA_ITEM_FAILURE,
        payload: {
            error: error
        } as TYPES.EditorPayload
    };
}

/*
 * 
 * REMOVE CAMERA STATE MESSAGES
 * 
 */

function removeCameraSuccess(videoboard: IAVLegacyVideoBoard): TYPES.EditorTypes {
    return {
        type: TYPES.REMOVE_CAMERA_ITEM_SUCCESS,
        payload: {
            videoboard: videoboard
        } as TYPES.EditorPayload
    };
}

function removeCameraFailure(error: Error): TYPES.EditorTypes {
    return {
        type: TYPES.REMOVE_CAMERA_ITEM_FAILURE,
        payload: {
            error: error
        } as TYPES.EditorPayload
    };
}

/*
 * 
 * ADD PAGE STATE MESSAGES
 * 
 */


function addPageSuccess(videoboard: IAVLegacyVideoBoard): TYPES.EditorTypes {
    return {
        type: TYPES.ADD_PAGE_SUCCESS,
        payload: {
            videoboard: videoboard
        } as TYPES.EditorPayload
    };
}

function addPageFailure(error: Error): TYPES.EditorTypes {
    return {
        type: TYPES.ADD_PAGE_FAILURE,
        payload: {
            error: error
        } as TYPES.EditorPayload
    };
}

/*
 * 
 * REMOVE PAGE STATE MESSAGES
 * 
 */


function removePageSuccess(videoboard: IAVLegacyVideoBoard): TYPES.EditorTypes {
    return {
        type: TYPES.REMOVE_PAGE_SUCCESS,
        payload: {
            videoboard: videoboard
        } as TYPES.EditorPayload
    };
}

function removePageFailure(error: Error): TYPES.EditorTypes {
    return {
        type: TYPES.REMOVE_PAGE_FAILURE,
        payload: {
            error: error
        } as TYPES.EditorPayload
    };
}

/*
 * 
 * RESIZE PAGE STATE MESSAGES
 * 
 */


function resizePageSuccess(videoboard: IAVLegacyVideoBoard): TYPES.EditorTypes {
    return {
        type: TYPES.RESIZE_PAGE_SUCCESS,
        payload: {
            videoboard: videoboard
        } as TYPES.EditorPayload
    };
}

function resizePageFailure(error: Error): TYPES.EditorTypes {
    return {
        type: TYPES.RESIZE_PAGE_FAILURE,
        payload: {
            error: error
        } as TYPES.EditorPayload
    };
}

/*
 * 
 * SAVE VIDEOBOARD STATE MESSAGES
 * 
 */ 

function saveVideoboardBegin(): TYPES.EditorTypes {
    return {
        type: TYPES.SAVE_VIDEOBOARD_BEGIN,
        payload: {} as TYPES.EditorPayload
    };
}

function saveVideoboardSuccess(response: IProcessedResponse): TYPES.EditorTypes {
    return {
        type: TYPES.SAVE_VIDEOBOARD_SUCCESS,
        payload: {
            videoboard: new AVLegacyVideoBoard(response.data) as IAVLegacyVideoBoard
        } as TYPES.EditorPayload
    };
}

function saveVideoboardFailure(error: Error): TYPES.EditorTypes {
    return {
        type: TYPES.SAVE_VIDEOBOARD_FAILURE,
        payload: {
            error: error
        } as TYPES.EditorPayload
    };
}

/*
 * 
 * SAVE PAGE STATE STATE MESSAGES 
 * 
 */
function savePageStateSuccess(
    currentPageIndex: number, 
    rowSize: number, 
    columnSize: number
): TYPES.EditorTypes {
    return {
        type: TYPES.SAVE_PAGE_STATE,
        payload: {
            currentPageIndex: currentPageIndex,
            rowSize: rowSize,
            columnSize: columnSize
        } as TYPES.EditorPayload
    };
}

function clearError(): TYPES.EditorTypes {
    return {
        type: TYPES.CLEAR_EDITOR_ERROR,
        payload: {} as TYPES.EditorPayload
    };
}

/*
 * 
 * GET VIDEOBOARD LIST STATE MESSAGES 
 * 
 */
function getVideoboardListBegin(): TYPES.EditorTypes {
    return {
        type: TYPES.GET_VIDEOBOARD_LIST_BEGIN,
        payload: {} as TYPES.EditorPayload
    };
}

function getVideoboardListSuccess(
    response: IProcessedResponse
): TYPES.EditorTypes {
    return {
        type: TYPES.GET_VIDEOBOARD_LIST_SUCCESS,
        payload: { 
            list: response.data.map( (board: IAVLegacyVideoBoardDto) => new AVLegacyVideoBoard(board)),
            response: response
        } as TYPES.EditorPayload
    };
}

function getVideoboardListFailure(error: Error): TYPES.EditorTypes {
    return {
        type: TYPES.GET_VIDEOBOARD_LIST_FAILURE,
        payload: {
            error: error
        } as TYPES.EditorPayload
    };
}


function deleteVideoboardBegin(): TYPES.EditorTypes {
    return {
        type: TYPES.DELETE_VIDEOBOARD_BEGIN,
        payload: {} as TYPES.EditorPayload
    };
}

function deleteVideoboardSuccess(
    response: IProcessedResponse
): TYPES.EditorTypes {
    return {
        type: TYPES.DELETE_VIDEOBOARD_SUCCESS,
        payload: { 
            list: response.data.map( (board: IAVLegacyVideoBoardDto) => new AVLegacyVideoBoard(board))
        } as TYPES.EditorPayload
    };
}

function deleteVideoboardFailure(error: Error): TYPES.EditorTypes {
    return {
        type: TYPES.DELETE_VIDEOBOARD_FAILURE,
        payload: {
            error: error
        } as TYPES.EditorPayload
    };
}

export let initializeVideoboardEditor = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    var videoboard = new AVLegacyVideoBoard();
    videoboard.addPage(2, 2);

    dispatch(initializeSuccess(videoboard));
}

export let loadVideoboard = (
    videoboardId: number
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {

    getAccessToken().then(
        (token: string) => {
            let manager: ILegacyVideoBoardNetworkManager = 
                new LegacyVideoBoardNetworkManager(apiUrlVideoboard);

            manager.setAccessToken(token);

            let getVideoboard: IGetByIdLegacyVideoBoard = {id: videoboardId};
            manager.getById(getVideoboard)
            .then(
                (response: IProcessedResponse) => dispatch(loadSuccess(response))
            ).catch(
                (err: Error) => dispatch(initializeFailure(err))
            )
        }
    ).catch(
        (err: Error) => dispatch(initializeFailure(err))
    )
}

export let addPage = (rowSize: number, columnSize: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    try {
        var board = getState().editor.videoboard;

        board.addPage(rowSize, columnSize);

        dispatch(addPageSuccess(board));
    }
    catch (err: any) {
        dispatch(addPageFailure(new Error(err)));
    }
}

export let removePage = (index: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    try {
        var board = getState().editor.videoboard;
        board.removePage(index);
        dispatch(removePageSuccess(board));
    }
    catch (err: any) {
        dispatch(removePageFailure(new Error(err)));
    }
}

export let resizePage = (index: number, rowSize: number, colSize: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    try {
        var board = getState().editor.videoboard;
        board.videoPages[index].resizePage(rowSize, colSize);
        dispatch(resizePageSuccess(board));
    }
    catch (err: any) {
        dispatch(resizePageFailure(new Error(err)));
    }
}

export let setName = (
    name: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    try {
        var videoboard = getState().editor.videoboard;
        videoboard.name = name;
        dispatch(setNameSuccess(videoboard));
    }
    catch (err: any) {
        dispatch(setNameFailure(new Error(err)));
    }
}

export let setCameraToVideoItem = (
    pageLocation: number, 
    itemLocation: number, 
    cameraId: number
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    try {
        var board = getState().editor.videoboard;

        if (board) {

            var item = board.videoPages
                .forEach((page: any, pageIndex: any) => {
                    if (page.placement === pageLocation) {
                        page.videoItems.forEach((item: any, itemIndex: any) => {
                            if (item.placement === itemLocation) {
                                board.videoPages[pageIndex].videoItems[itemIndex].cameraId = cameraId;
                            }
                        });
                    }
                });

            dispatch(setCameraSuccess(board));
        }
        else {
            dispatch(setCameraFailure(new Error("Cannot find videoboard in state")));
        }
    }
    catch (err: any) {
        dispatch(setCameraFailure(new Error(err)));
    }
}

export let removeCameraFromVideoItem = (
    pageLocation: number, 
    itemLocation: number
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    try {
        var board = getState().editor.videoboard;

        if (board) {

            var item = board.videoPages
                .forEach((page: any, pageIndex: any) => {
                    if (page.placement === pageLocation) {
                        page.videoItems.forEach((item: any, itemIndex: any) => {
                            if (item.placement === itemLocation) {
                                board.videoPages[pageIndex].videoItems[itemIndex].cameraId = 0;
                            }
                        });
                    }
                });

            dispatch(removeCameraSuccess(board));
        }
        else {
            dispatch(removeCameraFailure(new Error("Cannot find videoboard in state")));
        }
    }
    catch (err: any) {
        dispatch(removeCameraFailure(new Error(err)));
    }
}

export let save = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(saveVideoboardBegin());
    let boardToSave: IAVLegacyVideoBoard = getState().editor.videoboard;

    getAccessToken().then(
        (token: string) => {
            let manager: ILegacyVideoBoardNetworkManager = 
                new LegacyVideoBoardNetworkManager(apiUrlVideoboard);

            manager.setAccessToken(token);

            if (boardToSave.id === 0){

                let createVideoBoard: ICreateLegacyVideoBoard = {
                    videoboard: boardToSave,
                };

                manager.create(createVideoBoard)
                .then(
                    (response: IProcessedResponse) => dispatch(saveVideoboardSuccess(response))
                ).catch(
                    (err: Error) => dispatch(saveVideoboardFailure(err))
                )
            }
            else {
                let updateVideoBoard: IUpdateLegacyVideoBoard = {
                    videoboard: boardToSave,
                    id: boardToSave.id
                };

                manager.update(updateVideoBoard)
                .then(
                    (response: IProcessedResponse) => dispatch(saveVideoboardSuccess(response))
                ).catch(
                    (err: Error) => dispatch(saveVideoboardFailure(err))
                )
            }
        }
    ).catch(
        (err: Error) => dispatch(saveVideoboardFailure(err))
    )
}

export let savePageState = (
    currentPageIndex: number, 
    rowSize: number = 0, 
    colSize: number = 0
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    var sanitizedRow = rowSize !== 0 ? rowSize : getState().editor.rowSize;
    var sanitizedCol = colSize !== 0 ? colSize : getState().editor.columnSize;

    dispatch(savePageStateSuccess(currentPageIndex, sanitizedRow, sanitizedCol));
}

export let clearEditorState = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().editor.initialized && !getState().editor.loading) {
        dispatch({
            type: TYPES.CLEAR_EDITOR_STATE
        });
    }
}

export let clearEditorError = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(clearError());
}

export let getVideoboardList = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {   
    dispatch(getVideoboardListBegin());

    getAccessToken().then(
        (token: string) => {
            let manager: ILegacyVideoBoardNetworkManager = 
                new LegacyVideoBoardNetworkManager(apiUrlVideoboard);

            manager.setAccessToken(token);

            manager.getAll()
            .then(
                (response: IProcessedResponse) => dispatch(getVideoboardListSuccess(response))
            ).catch(
                (err: Error) => dispatch(getVideoboardListFailure(err))
            )
        }
    ).catch(
        (err: Error) => dispatch(getVideoboardListFailure(err))
    )
}

export let deleteVideoboard = (
    videoboard: IAVLegacyVideoBoard
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(deleteVideoboardBegin());

    getAccessToken().then(
        (token: string) => {
            let manager: ILegacyVideoBoardNetworkManager = new LegacyVideoBoardNetworkManager(apiUrlVideoboard);
            manager.setAccessToken(token);

            let deleteVideoboard: IDeleteLegacyVideoBoard = {
                id: videoboard.id
            }

            manager.delete(deleteVideoboard)
            .then(
                (response: IProcessedResponse) => {
                    manager.getAll()
                    .then(
                        ( listResponse: IProcessedResponse) => {
                            dispatch(deleteVideoboardSuccess(listResponse))
                        }
                    ).catch(
                        (err: Error) => {
                            dispatch(getVideoboardListFailure(err))
                        }
                    )
                }
            ).catch(
                (err: Error) => dispatch(deleteVideoboardFailure(err))
            )
        }
    ).catch(
        (err: Error) => dispatch(deleteVideoboardFailure(err))
    )
}