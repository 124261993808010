// libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";
// models & interfaces
import { IATCamera } from '@algo/network-manager/models/v3';
// enums
import { EATRegion } from '@algo/network-manager/models/v3';
// components
import DatasourceSelector from '../DatasourceSelector';
import SearchBox from '../SearchBox';
import DraggableCamera from './DraggableCamera';
// store
import { getCameras, getCamerasForEditor } from '../../../store/camera/actions';
import { DatasourceState } from '../../../store/datasource/types';
import { CameraState } from '../../../store/camera/types';
import { AppState } from '../../../store';

interface StateProps {
    datasource: DatasourceState;
    camera: CameraState;
}

let mapStateToProps = (state: AppState) => {
    return {
        datasource: state.datasource,
        camera: state.camera
    }
}

interface DispatchProps {
    getCameras: typeof getCameras;
    getCamerasForEditor: typeof getCamerasForEditor;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getCameras: getCameras,
        getCamerasForEditor: getCamerasForEditor
    }, dispatch);
}

type CameraSelectorProps = StateProps & DispatchProps;

interface CameraSelectorState {
    selectedRegion: EATRegion;
    query: string;
}

class CameraSelector extends React.Component<CameraSelectorProps, CameraSelectorState> {
    constructor(props: Readonly<CameraSelectorProps>) {
        super(props);

        this.state = {
            selectedRegion: EATRegion.Unknown,
            query: ''
        } as CameraSelectorState;
    }

    componentDidMount() {
        this.props.getCameras();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.props.camera.items.length !== prevProps.camera.items.length) {
            this.props.getCamerasForEditor(this.state.selectedRegion, this.state.query);
        }
        else if (this.state.selectedRegion !== prevState.selectedRegion) {
            this.props.getCamerasForEditor(this.state.selectedRegion, this.state.query);
        }
        else if (this.state.query !== prevState.query) {
            this.props.getCamerasForEditor(this.state.selectedRegion, this.state.query);
        }
    }

    componentWillUnmount() {
        this.props.getCamerasForEditor(EATRegion.Unknown, '');
    }

    datsourceSelectorChange = (region: EATRegion): void => {

        this.setState((state, props) => {
            return {
                ...state,
                selectedRegion: region
            };
        });
    }

    cameraSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.persist();

        this.setState((state, props) => {
            return {
                ...state,
                query: event.target.value
            };
        });
    }

    renderCameraElements = (items: IATCamera[]): React.ReactNode => {
        if (items.length > 0) {
            return (
                items.map(
                    (item: IATCamera) => 
                        <DraggableCamera 
                            key={item.id} 
                            item={item} 
                            className={``} 
                        />
                    )
            )

        }
        else {
            return (
                <div className='av-center-alert-container'>
                    <div className='av-primary-alert av-nav-alert'>
                        No cameras found for given region and search term
                    </div>
                </div>
            );
        }
    }

    render() {
        const { datasource, camera } = this.props;

        const cameraElements = (camera.editorFilteredItems.length > 0) 
            ?   ( camera.editorFilteredItems.map(
                    (item, index) => 
                        <DraggableCamera 
                            key={item.id} 
                            item={item} 
                            className={``} 
                        />
                    )
                ) 
            : (
                <div className='av-center-alert-container'>
                    <div className='av-primary-alert av-nav-alert'>
                        No cameras found for given region and search term
                    </div>
                </div>
            );

        return (
            <div>
                <DatasourceSelector 
                    onChangeCallback={this.datsourceSelectorChange} 
                    disabled={datasource.loading} 
                />

                <SearchBox 
                    onChange={this.cameraSearchChange} 
                    disabled={datasource.loading} 
                />

                <div className='av-section av-section-cameras'>
                    <h3 className='sr-only'>Cameras</h3>
                    <div className='av-draggable-camera-display'>
                        <div className="av-draggable-camera-display-row">
                            {cameraElements}
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(CameraSelector);