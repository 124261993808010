// libraries
import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from 'redux';
import { nuon } from '@caps-mobile/common-lib';
// models & interfaces
import {
    MEDIA_STREAM_ACCESS,
    FIRST_RESPONDER_STREAM_ACCESS,
    PRIVATE_SECTOR_STREAM_ACCESS,
    INTERNAL_STREAM_ACCESS
} from '../../../models/Privileges';
import { IAAUserAuthorizationProfile } from '@algo/network-manager/models/v3/admin';
import { EATStreamAccessLevel, IATCamera } from '@algo/network-manager/models/v3';
// components
import {VideoJs, DefaultConfig} from "@caps-mobile/video-js";
// store
import { AppState } from '../../../store';
import { ProfileState } from '../../../store/profile/types';
import { User } from 'oidc-client';
import { videojsHtml5 } from '../../../constants/videojs-html5';

interface OwnProps {
    item: IATCamera | null;
    className: string;
    autoplay: boolean;
}

interface StateProps {
    user: User | undefined;
    profile: ProfileState;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user,
        profile: state.profile
    };
};

type StreamProps = OwnProps & StateProps;
 
interface StreamState {
    isFullscreen: boolean;
}

class Stream extends React.Component<StreamProps, StreamState> {
    streamNodeRef: React.RefObject<HTMLVideoElement>;

    constructor(props: Readonly<StreamProps>) {
        super(props);

        this.streamNodeRef = React.createRef();

        this.state = {
            isFullscreen: false
        } as StreamState;
    }

    isAvailableToUser = (
        item?: IATCamera | null, user?: User | null, 
        userProfile?: IAAUserAuthorizationProfile | null
    ) => {
        if (!item || !user || !userProfile) return false;

        let isNonExpiredUser: boolean = 
            nuon(user) && !user.expired;
        let hasAldot: boolean = 
            userProfile.hasPrivilege(INTERNAL_STREAM_ACCESS);
        let hasFirstResponder: boolean = 
            userProfile.hasPrivilege(FIRST_RESPONDER_STREAM_ACCESS) || hasAldot;
        let hasMedia: boolean = 
            userProfile.hasPrivilege(MEDIA_STREAM_ACCESS) || hasFirstResponder;
        let hasStakeholder: boolean = 
            userProfile.hasPrivilege(PRIVATE_SECTOR_STREAM_ACCESS) || hasMedia;

        switch (item.accessLevel) {
            case EATStreamAccessLevel.Public:
                return isNonExpiredUser;
            case EATStreamAccessLevel.Stakeholder:
                return isNonExpiredUser && hasStakeholder;
            case EATStreamAccessLevel.Media:
                return isNonExpiredUser && hasMedia;
            case EATStreamAccessLevel.FirstResponder:
                return isNonExpiredUser && hasFirstResponder;
            case EATStreamAccessLevel.ALDOT:
                return isNonExpiredUser && hasAldot;
            default:
                return false;
        }
    }

    renderImageContent = (item: IATCamera | null): React.ReactNode => {
        if (item) {
            return (
                <div className='unavailable'><span className='sr-only'>Unavailable</span></div>
            );
        }
        else {
            return (
                <div className='no-camera'><span className='sr-only'>No Camera</span></div>
            );
        }
    }

    renderContent = (item: IATCamera | null, isAvailable: boolean): React.ReactNode => {
        if ( item && isAvailable) {

            return (
                <VideoJs
                    style={{width: "100%", height: "100%"}}
                    videoClassNames={"av-stream-player video-js vjs-default-skin"}
                    
                    config={{
                        ...DefaultConfig, controls: true, playsInline: true, 
                        preload: "auto", autoPlay: true, 
                        sources: [{
                            src: item.hlsUrl,
                            type: "application/x-mpegURL"
                        }],
                        html5: videojsHtml5,
                        width: "100%",
                        height: "100%",
                    }}
                />
            );
        }
        else {
            return this.renderImageContent(item);
        }
    }

    render() {
        const { item, className, user, profile } = this.props;

        const isAvailable: boolean = 
            nuon(item) && 
            nuon(user) && 
            this.isAvailableToUser(item, user, profile.userProfile);

        return (
            <div className={`av-stream-container ${className}`}>
                <div className='av-stream'>
                    <div className='av-stream-content'>
                        <div className={`av-stream-title ${item ? 'visible' : 'invisible'}`}>
                            <div className='av-primary'>
                                {item ? item.location.displayRouteDesignator : 'Unknown'}
                            </div>
                            <div className='av-secondary'>
                                {item ? item.location.crossStreet : 'Unknown'}
                            </div>
                        </div>
                        <div className={`${!isAvailable ? 'av-unavailable-wrapper' : 'av-stream-wrapper'}`}>
                            {this.renderContent(item, isAvailable)}
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}

export default connect(
    mapStateToProps, 
)(Stream);