import { 
    combineReducers,
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import createOidcMiddleware, {
    loadUser,
    reducer,
} from 'redux-oidc';
import thunkMiddleware from 'redux-thunk';
import { datasourceReducer } from './datasource/reducers';
import { profileReducer } from './profile/reducers';
import { cameraReducer } from './camera/reducers';
import { editorReducer } from './videoboard/reducers';
import userManager from '../utils/userManager';

let rootReducer = combineReducers({
    datasource: datasourceReducer,
    profile: profileReducer,
    camera: cameraReducer,
    editor: editorReducer,
    oidc: reducer
});

export type AppState = ReturnType<typeof rootReducer>;

let oidcMiddleware = createOidcMiddleware(userManager);

let createStoreWithMiddleware = 
    compose(applyMiddleware(thunkMiddleware, oidcMiddleware))(createStore);

let initialState = {};
let store = createStoreWithMiddleware(rootReducer, initialState);

loadUser(store, userManager);

export default store;