// libraries
import * as React from 'react';
// components
import Content from '../base/layout/Content';
// resources
import { videoGuide } from '../../images';

export default class SiteGuide extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        <div className='av-guide-content'>
                            <h1 className='sr-only'>Site Guide</h1>
                            <object 
                                className='av-site-guide' 
                                data={videoGuide.default} 
                                type='application/pdf'
                            >
                                <param name='src' value={videoGuide.default} />
                            </object>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}