import * as React from 'react';

interface ConfrimationModalProps {
    dismissCallback: (() => void);
    confirmCallback: (() => void);
    title: string;
    message: string;
    type: string;
}

interface ConfirmationModalState {
    instanceId: number;
}

export default class ConfirmationModal extends React.Component<ConfrimationModalProps, ConfirmationModalState> {
    modalRef: React.RefObject<HTMLDivElement>;

    constructor(props: Readonly<ConfrimationModalProps>) {
        super(props);

        this.modalRef = React.createRef<HTMLDivElement>();

        this.state = {
            instanceId: Math.random()
        } as ConfirmationModalState;
    }

    componentDidMount() {
        this.modalRef.current &&
            ($(this.modalRef.current) as any).modal('show');
    }

    dismiss = (event: React.MouseEvent<HTMLElement>): void => {
        const { dismissCallback } = this.props;

        event.preventDefault();

        this.modalRef.current &&
            ($(this.modalRef.current) as any).modal('hide');

        dismissCallback();
    }

    confirm = (event: React.MouseEvent<HTMLElement>): void => {
        const { confirmCallback } = this.props;

        event.preventDefault();

        this.modalRef.current &&
            ($(this.modalRef.current) as any).modal('hide');

        confirmCallback();
    }

    render() {
        const { title, message, type } = this.props;


        return (
            <div 
                className='modal fade' ref={this.modalRef} id={`confirmationModal_${this.state.instanceId}`} 
                tabIndex={-1} role='dialog' data-keyboard='false' data-backdrop='static' 
                aria-labelledby={`confirmationModalTitle_${this.state.instanceId}`} aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id={`confirmationModalTitle_${this.state.instanceId}`}>
                                {title}
                            </h5>
                        </div>
                        <div className='modal-body'>
                            <div className={`alert alert-${type}`}>
                                {message}
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <div className='btn-group' role='group' aria-label='Confirmation modal actions'>
                                <button className='btn btn-dark' onClick={this.dismiss}>
                                    <i className='fa fa-times-circle'></i> 
                                    Dismiss
                                </button>
                                <button className='btn btn-primary' onClick={this.confirm}>
                                    <i className='fa fa-check-circle'></i> 
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}