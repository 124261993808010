//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { Action } from "redux";
import { getAccessToken } from "../../utils/data/getAccessToken";
//types
import {
    CameraTypes, CameraPayload,
    FETCH_CAMERAS_BEGIN, FETCH_CAMERAS_SUCCESS, FETCH_CAMERAS_FAILURE, FETCH_CAMERAS_NOCHANGE,
    FILTER_DATASOURCES_CAMERA_BEGIN, FILTER_DATASOURCES_CAMERA_SUCCESS, FILTER_DATASOURCES_CAMERA_FAILURE,
    REFRESH_CAMERA_LISTS_BEGIN, REFRESH_CAMERA_LISTS_SUCCESS, REFRESH_CAMERA_LISTS_FAILURE,
    FILTER_EDITOR_CAMERA_BEGIN, FILTER_EDITOR_CAMERA_SUCCESS, FILTER_EDITOR_CAMERA_FAILURE, CLEAR_CAMERA_ERROR
} from "./types";
//models
import { 
    ATCamera, IATCamera, EATRegion, IATCameraDto 
} from "@algo/network-manager/models/v3";
import { 
    CameraNetworkManager, ICameraNetworkManager, IProcessedResponse, EHttpRequestMethod 
} from "@algo/network-manager/managers/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENPOINTS} from "../api-version-constants";
declare var __API_URL__: string;
const apiUrlCamera: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENPOINTS(CUR_API_VERSION).cameras}`;

/*
 * 
 * FETCH CAMERAS STATE MESSAGES
 * 
 */

function fetchCamerasBegin(): CameraTypes {
    return {
        type: FETCH_CAMERAS_BEGIN,
        payload: {} as CameraPayload
    };
}

function fetchCamerasSuccess(response: IProcessedResponse): CameraTypes {
    return {
        type: FETCH_CAMERAS_SUCCESS,
        payload: {
            response: response
        } as CameraPayload
    };
}

function fetchCamerasFailure(error: Error): CameraTypes {
    return {
        type: FETCH_CAMERAS_FAILURE,
        payload: {
            error: error
        } as CameraPayload
    };
}

/*
 * 
 * FILTER DATASOURCE CAMERAS STATE MESSAGES
 * 
 */

function filterDatasourceCamerasBegin(): CameraTypes {
    return {
        type: FILTER_DATASOURCES_CAMERA_BEGIN,
        payload: {} as CameraPayload
    };
}

function filterDatasourceCamerasSuccess(datasourceCameras: ATCamera[]): CameraTypes {
    return {
        type: FILTER_DATASOURCES_CAMERA_SUCCESS,
        payload: {
            mgmtFilteredItems: datasourceCameras
        } as CameraPayload
    };
}

function filterDatasourceCamerasFailure(error: Error): CameraTypes {
    return {
        type: FILTER_DATASOURCES_CAMERA_FAILURE,
        payload: { 
            error: error
        } as CameraPayload
    };
}


/*
 * 
 * REFRESH CAMERA LISTS STATE MESSAGES
 * 
 */

function refreshCameraListsBegin(): CameraTypes {
    return {
        type: REFRESH_CAMERA_LISTS_BEGIN,
        payload: {} as CameraPayload
    };
}

function refreshCameraListsSuccess(response: IProcessedResponse, filteredItems: IATCamera[]): CameraTypes {
    return {
        type: REFRESH_CAMERA_LISTS_SUCCESS,
        payload: {
            mgmtFilteredItems: filteredItems,
            response: response
        } as CameraPayload
    };
}

function refreshCameraListsFailure(error: Error): CameraTypes {
    return {
        type: REFRESH_CAMERA_LISTS_FAILURE,
        payload: { 
            error: error
        } as CameraPayload
    };
}

/*
 * 
 * CLEAR CAMERA ERROR STATE MESSAGES
 * 
 */ 

function clearCameraError(): CameraTypes {
    return {
        type: CLEAR_CAMERA_ERROR,
        payload: {} as CameraPayload
    };
}

/*
 * 
 * FILTER EDITOR CAMERAS STATE MESSAGES
 * 
 */

function filterEditorCamerasBegin(): CameraTypes {
    return {
        type: FILTER_EDITOR_CAMERA_BEGIN,
        payload: {} as CameraPayload
    };
}

function filterEditorCamerasSuccess(filteredItems: ATCamera[]): CameraTypes {
    return {
        type: FILTER_EDITOR_CAMERA_SUCCESS,
        payload: {
            editorFilteredItems: filteredItems
        } as CameraPayload
    };
}

function filterEditorCamerasFailure(error: Error): CameraTypes {
    return {
        type: FILTER_EDITOR_CAMERA_FAILURE,
        payload: {
            error: error
        } as CameraPayload
    };
}


/*
 *
 * CAMERA REDUX ACTIONS
 * 
 */

export function clearCameraStoreError() {
    return (dispatch: any, getState: any) => {
        dispatch(clearCameraError());
    };
}

export let getCameras = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) =>  {
    dispatch(fetchCamerasBegin());

    getAccessToken().then(
        (token) => {
            let manager: ICameraNetworkManager = new CameraNetworkManager(apiUrlCamera);
            manager.setAccessToken(token);
            manager.getAll(
                getState().camera.lastResponse, {}
            ).then(
                (processed: IProcessedResponse) => {
                    dispatch(fetchCamerasSuccess(processed))
                }
            ).catch(
                (error: any) => {
                    dispatch(fetchCamerasFailure(new Error(error)));
                }
            )
        }
    ).catch(
        (error: Error) => {
            dispatch(fetchCamerasFailure(error))
        }
    )
}

export let getCamerasForManagement = (
    region: EATRegion, 
    query: string = ""
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {

    if (getState().camera.items.length > 0) {
        dispatch(filterDatasourceCamerasBegin());

        if (isKnownRegion(region)) {
            try {
                let filtered = filterCameras(getState().camera.items, region, query);
                dispatch(filterDatasourceCamerasSuccess(filtered));
            }
            catch (err: any) {
                dispatch(filterDatasourceCamerasFailure(new Error(err)));
            }
        }
        else {
            dispatch(filterDatasourceCamerasSuccess([]));
        }
    }
}

export let getCamerasForEditor = (
    region: EATRegion, 
    query: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().camera.items.length > 0) {
        dispatch(filterEditorCamerasBegin());

        if (isKnownRegion(region)) {
            try {
                let filtered = filterCameras(getState().camera.items, region, query);

                dispatch(filterEditorCamerasSuccess(filtered));
            }
            catch (err: any) {
                dispatch(filterEditorCamerasFailure(new Error(err)));
            }
        }
        else {
            dispatch(filterEditorCamerasSuccess([]));
        }
    }
}

export let refreshCameraLists = (
    region: EATRegion, 
    query: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    
    dispatch(refreshCameraListsBegin());

    getAccessToken().then(
        (token) => {
            let manager: ICameraNetworkManager = new CameraNetworkManager(apiUrlCamera);
            manager.setAccessToken(token);
            manager.getAll(
                getState().camera.lastResponse, {}
            )
            .then((response: any) => {
                console.warn("Refresh Camera List", response);

                let filtered = filterCameras(getState().camera.items, region, query);

                
                dispatch(
                    refreshCameraListsSuccess(
                        response?.data.map( (camera: IATCameraDto) => new ATCamera(camera)), 
                        filtered
                    )
                );
            }, (failure: any) => {
                console.error("Refresh Camera List Failure", failure);

                dispatch(refreshCameraListsFailure(new Error(failure)));
            })
            .catch((error: any) => {
                console.error("Refresh Camera List Error", error);

                dispatch(refreshCameraListsFailure(new Error(error)));
            });
        }
    ).catch(
        (error: Error) => {
            dispatch(refreshCameraListsFailure(error))
        }
    )
}

function filterCameras(
    cameraItems: IATCamera[], 
    region: EATRegion, 
    query: string
) {
    return cameraItems.filter(
        (item: IATCamera) => {
            if (!item || !item.location || !item.location.routeDesignator)
                return false;
            if (query && isSpecifiedRegion(region)) {
                return (
                    (   
                        item.location.routeDesignator.toUpperCase().includes(query.toUpperCase()) || 
                        item.location.crossStreet?.toUpperCase().includes(query.toUpperCase())
                    ) 
                        && item.responsibleRegion === region
                );
            }
            else if (query) {
                return (
                    item.location.routeDesignator.toUpperCase().includes(query.toUpperCase()) || 
                    item.location.crossStreet?.toUpperCase().includes(query.toUpperCase())
                );
            }
            else if (isSpecifiedRegion(region)) {
                return item.responsibleRegion === region;
            }
            else {
                return true;
            }
    });
}

const isSpecifiedRegion = (region: EATRegion) => {
    return (
        region !== EATRegion.ALDOT &&
        region !== EATRegion.Unknown
    );
}

const isKnownRegion = (region: EATRegion) => {
    return ( region !== EATRegion.Unknown );
}