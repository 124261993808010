/// <reference path='./index.d.ts' />
import * as logoImg from './algo-logo.png';
import * as cameraDisabledImg from './camera-disabled.png';
import * as cameraLoadingPreviewImg from './camera-loading-preview.png';
import * as noCameraImg from './camera-no-camera.png';
import * as noCameraPreviewImg from './camera-no-preview.png';
import * as cameraUnavailableImg from './camera-unavailable.png';
import * as videoGuidePdf  from './video_guide.pdf';
import * as guiLeftArrow from './gui/gui-arrow-left.png';
import * as guiRightArrow from './gui/gui-arrow-right.png';
import * as guiSearch from './gui/gui-search.png';

export let algoLogo = logoImg;
export let cameraDisabled = cameraDisabledImg;
export let cameraLoadingPreview = cameraLoadingPreviewImg;
export let noCamera = noCameraImg;
export let noCameraPreview = noCameraPreviewImg;
export let cameraUnavailable = cameraUnavailableImg;
export let videoGuide = videoGuidePdf;
export let leftArrow = guiLeftArrow;
export let rightArrow = guiRightArrow;
export let searchIcon = guiSearch;
