//libraries
import * as React from 'react';

const COLOR_CONSTANTS: any = {
    archiveCyan: "linear-gradient(45deg, rgba(38,111,140,1) 0%,rgba(58,174,194,1) 12%,rgba(76,226,240,1) 29%,rgba(66,192,214,1) 50%,rgba(97,179,199,1) 60%,rgba(97,179,199,1) 60%,rgba(58,165,194,1) 74%,rgba(58,165,194,1) 74%,rgba(170,210,222,1) 100%,rgba(207,226,233,1) 100%)",
    blue: "dodgerBlue",
    white: "#fff",
    secondary: "antiqueWhite",
    greyMedium: "rgb(118,131,143)",
    grayMedium: "rgb(118, 131, 143)",
    greyLight: "WhiteSmoke",
    grayLight: "WhiteSmoke",
}

const DEFAULT_STYLES: any = {
    SELECTION_ROW_BLOCKS: {
        overflowX: "auto" as "auto",
        fontSize: "16px",
        color: COLOR_CONSTANTS.grayMedium,
        "WebkitBoxShadow": "inset 0px 0px 12px -2px rgba(0,0,0,0.19)",
        "MozBoxShadow": "inset 0px 0px 12px -2px rgba(0, 0, 0, 0.19)",
        "BoxShadow": "inset 0px 0px 12px -2px rgba(0, 0, 0, 0.19)",
    },
    NAV_BUTTON: {
        cursor: "pointer",
    },
    BLOCK_WRAPPER: {
        cursor: "pointer",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "10px",
        backgroundColor: COLOR_CONSTANTS.greyLight,
        active: {
            background: COLOR_CONSTANTS.archiveCyan,
            color: COLOR_CONSTANTS.white,
            borderRadius: "10px",
        }
    },
    BLOCK_TITLE: {
        textAlign: "center",
        fontSize: "32px",
        minWidth: "70px",
        cursor: "pointer",
        active: {
            //
        }
    },
    BLOCK_SUBTITLE: {
        textAlign: "center",
        minWidth: "70px",
        cursor: "pointer",
        active: {
            //
        }
    }
}

/* Used by SelectionRow and OptionObject to combine the default styles with the user-supplied styles */
const compileStyles = (style1: any = null, style2: any = null, ruleString: string): any => {
    if (!style1 && !style2) return {};                              //if both null, return empty style object
    if (!style1 && style2) return style2[ruleString];               //if only style2, return style2 rule
    if (style1 && !style2) return style1[ruleString];               //if only style1, return style1 rule
    let compiledStyles: any = {         
        ...style1[ruleString],  
        ...style2[ruleString],
    }
    return compiledStyles;                                          //otherwise return the two rules combined
}

/*  
 *  <Description>
 *      
 *      Displays a list of options for the User to select from.
 *      When an option is chosen (either by a MouseClick, Enter Keypress, or Button increment/decrement) the Component state will be returned to parent via callbackAction prop
 *              
 *  </Description>
 */

interface IProps {
    defaultStyles?: boolean;                //flag to signal self-styling
    defaultSelection?: number;              //if missing, there will be no active selection by default ( defaults to value -1 ).
    navButtonObjects?: Array<any>;          //an array of objects containing information about NavButtons for the SelectionRow. If absent, no buttons are used ( current design expects a left button and right button, but there is room for expansion ).
    styles?: any;                           //styling object supplied by parent, if exists, will be decomposed into compiledStyles object, overwriting defaults if defaults flag is true (styling object MUST match the formatting of default object to guarantee success)
    optionBlocks: Array<any>;               //array of objects containing information about the choices available in the SelectionRow. Used by OptionBlock child Component to build up the contents of SelectionRow.
    callbackAction: any;                    //callback function called when an option is selected. function should expect to receive the Component state of SeletionRow as an argument
}

interface IState {
    selectedIndex: number;
}

export class SelectionRow extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            selectedIndex: this.validateDefaultSelection(),
        }
        this.props.callbackAction(this.state);                                                          //update the parent with the default selection
    }

    /*************************************************************************************************************************************************
                                                                    Lifecycle Functions    
    ************************************************************************************************************************************************/

    componentDidUpdate(prevProps: any) {
        if (prevProps.optionBlocks.length !== this.props.optionBlocks.length)
            if (this.props.defaultSelection) this.validateDefaultSelection();
    }

    /*************************************************************************************************************************************************
                                                                        Main Render    
    ************************************************************************************************************************************************/

    render() {
        if (this.props.optionBlocks.length === 0) return null;                                  //if empty list of options was supplied, cancel render
        return (
            <div className="container-fluid">
                <div className="selection-row row d-flex align-items-center justify-content-center">
                    {this.renderButton(0)}                                                          {/* render button 0, if it exists */}
                    <div className="col overflow-auto">
                        <div className="row d-flex selection-row-blocks flex-nowrap" style={compileStyles(this.props.defaultStyles, this.props.styles, "SELECTION_ROW_BLOCKS")}>
                            {this.props.optionBlocks.map(
                                (optionBlock, index) => {
                                    if (!optionBlock.hidden) {                                      //if the option was not set to hidden, render the option
                                        return (
                                            <OptionBlock
                                                key={Math.random()}                                 //to placate React
                                                config={optionBlock}                                //string variables, etc.
                                                active={this.determineActive(optionBlock)}          //OptionBlock style altered if active
                                                index={index}                                       //tell child its own index in parent list; can use this in callbackAction
                                                defaultStyles={this.props.defaultStyles}            //flag to signal self-styling if default styles are turned on
                                                callbackAction={this.handleBlockClick}              //when block detects it has been selected, set SelectionRow state.selectedIndex and call SelectionRow callbackAction with index
                                            />
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                    {this.renderButton(1)}                                                          {/* render button 1, if it exists */}
                </div>
            </div>
        );
    }

    /*************************************************************************************************************************************************
                                                                        Helper Render    
    ************************************************************************************************************************************************/

    /* render a NavButton in the option selection space based on the buttonSelector number provided */
    renderButton = (buttonSelector: number = 0) => { if (!this.props.navButtonObjects) return null;         //if buttons are not supplied, just return
        let imgObj: any = this.props.navButtonObjects[buttonSelector];                                      //get object from list 
        let floatClass = buttonSelector === 0 ? "float-left" : (buttonSelector === 1 ? "float-right" : "")  //decide which way to float-align 
        return (
            <div className="col-2">
                <div className={`selection-row-nav-button ${floatClass}`}>                                  {/* apply the float class */}
                    <img
                        className="selection-row-img"
                        id={`selection-row-img-${buttonSelector}`}                      //buttonSelector value may be parsed later by event listeners
                        src={imgObj.src} alt={imgObj.alt} width={imgObj.width} height={imgObj.height}                       //img config from object
                        style={this.props.defaultStyles ? DEFAULT_STYLES.NAV_BUTTON : undefined}                            //styling if defaulted
                        tabIndex={0} onClick={this.selectOnClickFunction(buttonSelector)} onKeyUp={this.handleKeyUp}        //listeners/ui reactions
                    />
                </div>
            </div>     
        );
    }

    /*************************************************************************************************************************************************
                                                                        Logic Functions    
    ************************************************************************************************************************************************/

    /* Used to determine if the given Block is currently selected */
    determineActive = (optionBlock: any) => {
        if (this.state.selectedIndex === null || this.state.selectedIndex === undefined || this.state.selectedIndex === -1) return false; //if NOTHING selected, false
        return (optionBlock.id === this.props.optionBlocks[this.state.selectedIndex].id);                       //if current Block id and selectedBlock id match, true
    }

    /* this function determines what function should be called when the corresponding NavButton is clicked */
    selectOnClickFunction = (buttonSelector: number) => {
        if (this.props.navButtonObjects) {
            let imgObj: any = this.props.navButtonObjects[buttonSelector];                              //get the image object for the given index selector
            if (buttonSelector === 0) return this.decrementSelection;                                   //index 0 reserved for the decrement function object
            else if (buttonSelector === 1) return this.incrementSelection;                              //index 1 reserved for the increment function object
            else if (imgObj.onClick) return imgObj.onClick;                                             //remaining indicies should supply their own function
        }
        return () => { return null };                                                                   //no function supplied, means null function returned
    }

    /* Confirm that the current value of defaultSelection is valid */
    validateDefaultSelection = () => {
        let selectedIndex = this.props.defaultSelection;                                                //rename for brevity
        if (
            selectedIndex === null      ||                                                              //cannot be null
            selectedIndex === undefined ||                                                              //must be defined
            selectedIndex < 0           ||                                                              //must be greater than 0
            selectedIndex > (this.props.optionBlocks.length - 1)                                        //must be an index within the bounds of the list
        )   selectedIndex = -1;                                                                         //if any condition fails, default value is -1 (unselected)
        return selectedIndex;                                                                           //return the validated prop, or default value
    }

    /*************************************************************************************************************************************************
                                                                        Event Handlers    
    ************************************************************************************************************************************************/
    /* The default function implemented by NavButton 0 (the 'go back one' function of option selection) */
    decrementSelection = () => {
        if (this.state.selectedIndex <= 0) return null;                                                     //if selection 0 just return
        this.setState({
            ...this.state, selectedIndex: ( this.state.selectedIndex - 1 ),                                 //update state with new selection
        })
        this.props.callbackAction({ ...this.state, selectedIndex: (this.state.selectedIndex - 1) });        //inform parent of the new selection
    }

    /* The default function implemented by NavButton 1 (the 'go forward one' function of option selection) */
    incrementSelection = () => {
        if (this.state.selectedIndex === ( this.props.optionBlocks.length - 1) ) return null;               //if selection max index, just return
        this.setState({
            ...this.state, selectedIndex: (this.state.selectedIndex + 1 ),                                  //update state with new selection
        })
        this.props.callbackAction({...this.state, selectedIndex: (this.state.selectedIndex + 1)});          //inform parent of the new selection
    }

    /* This is the callbackAction passed to the Block children so they may inform parent when they are interacted with */
    handleBlockClick = (index: number) => {                                                     
        this.setState({
            ...this.state,
            selectedIndex: index,
        });
        this.props.callbackAction({ ...this.state, selectedIndex: index });         //just pass the value they give you back up to your own callbackAction
    }
    
    /* Implemented by elements that want to respond as if they were clicked when the Enter key is pressed */
    handleKeyUp = (event: any) => {
        if (event.key === 'Enter') {                                                                //When the Enter key is pressed and released...
            let buttonSelector: number = parseInt(event.target.id[event.target.id.length - 1]);     //parse out the affected element's buttonSelector
            this.selectOnClickFunction(buttonSelector)();                                           //get and call the appropriate on click function
        }
    }
}

export default SelectionRow;












interface IOptionBlockProps {
    defaultStyles?: any;                            //if true, indicates that the Block should style itself using the defaults provided in this file
    config: any;                                    //object containing the string values of title and subtitle. In the future, may be abstracted as necessary to add other elements to a Block
    active: boolean;                                //identifies the Block as currently selected and changes its properties to reflect this
    index: number;                                  //tells the Block what index it is in the parent's list, used for callbackAction
    callbackAction: any;                            //allows the Block to communicate a change to its parent
}

interface IOptionBlockState {
    defaultTitleStyle: any;                         //exists to allow a state-ful change to the appearance of the Block title
    defaultSubtitleStyle: any;                      //exists to allow a state-ful change to the appearance of the Block subtitle
    defaultBlockWrapperStyle: any;                  //exists to allow a state-ful change to the appearance of the Block element
}

class OptionBlock extends React.Component<IOptionBlockProps, IOptionBlockState>{

    constructor(props: any) {   //get started with a default state
        super(props);
        const active = this.props.active;
        this.state = {
            defaultBlockWrapperStyle: active ? { ...DEFAULT_STYLES.BLOCK_WRAPPER, ...DEFAULT_STYLES.BLOCK_WRAPPER.active } : { ...DEFAULT_STYLES.BLOCK_WRAPPER },
            defaultTitleStyle: active ? { ...DEFAULT_STYLES.BLOCK_TITLE, ...DEFAULT_STYLES.BLOCK_TITLE.active} : { ...DEFAULT_STYLES.BLOCK_TITLE},
            defaultSubtitleStyle: active ? { ...DEFAULT_STYLES.BLOCK_SUBTITLE, ...DEFAULT_STYLES.BLOCK_SUBTITLE.active } : { ...DEFAULT_STYLES.BLOCK_SUBTITLE},
        }
    }

    /*************************************************************************************************************************************************
                                                                    Lifecycle Functions    
    ************************************************************************************************************************************************/

    componentDidUpdate(prevProps: any) {
        if (prevProps.active !== this.props.active) {                               //if props.active changed
            if (this.props.defaultStyles) {
                this.styleBlock();
            }
        }
    }

    /*************************************************************************************************************************************************
                                                                        Main Render    
    ************************************************************************************************************************************************/

    render() {
        if (this.props.config.hidden) return null;
        let active = this.props.active ? "active" : "";
        return (<div className={`col p-4 option-block-col d-flex align-items-center justify-content-center`}>
            <div
                className={`option-block-wrapper d-flex align-items-center justify-content-center h-100 w-100 ${active}`}
                style={this.props.defaultStyles ? this.state.defaultBlockWrapperStyle : {}}
                tabIndex={0}
                onClick={this.handleBlockClick}
                onKeyUp={this.handleKeyUp}
            >
                <div className={`option-block row d-flex justify-content-center align-items-center flex-nowrap`} >
                    {this.renderBlockImage()}
                    {this.renderBlockText()}
                </div>
            </div>
        </div>);
    }

    /*************************************************************************************************************************************************
                                                                        Helper Render    
    ************************************************************************************************************************************************/

    renderBlockImage = () => {
        if (!this.props.config.img) return null;
        let active = this.props.active ? "active" : "";
        return (
            <div className="col d-flex align-items-center justify-content-center">
                <div className={`option-block-img ${active}`}>
                    <img src={this.props.config.img.src} alt={this.props.config.img.alt} width={this.props.config.img.width} height={this.props.config.img.height} />
                </div>
            </div>
        );
    }

    renderBlockText = () => {
        if (!this.props.config.title && !this.props.config.subtitle) return null;
        else return (<div className="option-block-text d-inline-block col">{this.renderBlockTitle()} {this.renderBlockSubtitle()}</div>);
    }

    renderBlockTitle = () => {
        if (!this.props.config.title) return null;                                       //if no title was supplied, just return
        let active = this.props.active ? "active" : "";
        return (
            <div className={`option-block-title ${active}`} style={this.props.defaultStyles ? this.state.defaultTitleStyle : undefined}>{this.props.config.title}</div>
        );
    }
    
    renderBlockSubtitle = () => {
        if (!this.props.config.subtitle) return null;                                 //if no subtitle was supplied, just return
        let active = this.props.active ? "active" : "";
        return (
            <div className={`option-block-subtitle ${active}`} style={this.props.defaultStyles ? this.state.defaultSubtitleStyle : undefined}>{this.props.config.subtitle}</div>    
        );
    }

    /*************************************************************************************************************************************************
                                                                        Event Handlers
    ************************************************************************************************************************************************/

    handleBlockClick = (event: any) => {
        console.log(`a block was clicked`);
        console.log(`the blocks index is: `, this.props.index);
        console.log(`the block's active status is: `, this.props.active);
        this.props.callbackAction(this.props.index);
    }

    handleKeyUp = (event: any) => {
        if (event.key === 'Enter') {                                                                        //if Enter was pressed and released
            this.handleBlockClick(event);                                                                   //just call the onClick button
        }
    }


    /*************************************************************************************************************************************************
                                                                        Logic Functions
    ************************************************************************************************************************************************/

    styleBlock = () => {
        const active = this.props.active;
        this.setState({
            ...this.state,
            defaultBlockWrapperStyle: active ? { ...DEFAULT_STYLES.BLOCK_WRAPPER, ...DEFAULT_STYLES.BLOCK_WRAPPER.active } : { ...DEFAULT_STYLES.BLOCK_WRAPPER },
            defaultTitleStyle: active ? { ...DEFAULT_STYLES.BLOCK_TITLE, ...DEFAULT_STYLES.BLOCK_TITLE.active } : { ...DEFAULT_STYLES.BLOCK_TITLE },
            defaultSubtitleStyle: active ? { ...DEFAULT_STYLES.BLOCK_SUBTITLE, ...DEFAULT_STYLES.BLOCK_SUBTITLE.active } : { ...DEFAULT_STYLES.BLOCK_SUBTITLE },
        });
    }
}