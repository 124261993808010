import { IProcessedResponse } from '@algo/network-manager/managers/v3';
import { IATCamera } from '@algo/network-manager/models/v3';

export let FETCH_CAMERAS_BEGIN = 'FETCH_CAMERAS_BEGIN';
export let FETCH_CAMERAS_SUCCESS = 'FETCH_CAMERAS_SUCCESS';
export let FETCH_CAMERAS_FAILURE = 'FETCH_CAMERAS_FAILURE';
export let FETCH_CAMERAS_NOCHANGE = 'FETCH_CAMERAS_NOCHANGE';

export let UPDATE_ACCESS_LEVEL_BEGIN = 'UPDATE_ACCESS_LEVEL_BEGIN';
export let UPDATE_ACCESS_LEVEL_SUCCESS = 'UPDATE_ACCESS_LEVEL_SUCCESS';
export let UPDATE_ACCESS_LEVEL_FAILURE = 'UPDATE_ACCESS_LEVEL_FAILURE';
export let UPDATE_ACCESS_LEVEL_CLEAR = 'UPDATE_ACCESS_LEVEL_CLEAR';

export let MODIFY_CAMERA_INFO_BEGIN = 'MODIFY_CAMERA_INFO_BEGIN';
export let MODIFY_CAMERA_INFO_SUCCESS = 'MODIFY_CAMERA_INFO_SUCCESS';
export let MODIFY_CAMERA_INFO_FAILURE = 'MODIFY_CAMERA_INFO_FAILURE';

export let BULK_UPDATE_BEGIN = 'BULK_UPDATE_BEGIN';
export let BULK_UPDATE_SUCCESS = 'BULK_UPDATE_SUCCESS';
export let BULK_UPDATE_FAILURE = 'BULK_UPDATE_FAILURE';
export let BULK_UPDATE_CLEAR = 'BULK_UPDATE_CLEAR';

export let FILTER_DATASOURCES_CAMERA_BEGIN = 'FILTER_DATASOURCES_CAMERA_BEGIN';
export let FILTER_DATASOURCES_CAMERA_SUCCESS = 'FILTER_DATASOURCES_CAMERA_SUCCESS';
export let FILTER_DATASOURCES_CAMERA_FAILURE = 'FILTER_DATASOURCES_CAMERA_FAILURE';

export let FILTER_EDITOR_CAMERA_BEGIN = 'FILTER_EDITOR_CAMERA_BEGIN';
export let FILTER_EDITOR_CAMERA_SUCCESS = 'FILTER_EDITOR_CAMERA_SUCCESS';
export let FILTER_EDITOR_CAMERA_FAILURE = 'FILTER_EDITOR_CAMERA_FAILURE';

export let REFRESH_CAMERA_LISTS_BEGIN = 'REFRESH_CAMERA_LISTS_BEGIN';
export let REFRESH_CAMERA_LISTS_SUCCESS = 'REFRESH_CAMERA_LISTS_SUCCESS';
export let REFRESH_CAMERA_LISTS_FAILURE = 'REFRESH_CAMERA_LISTS_FAILURE';

export let CLEAR_CAMERA_ERROR = 'CLEAR_CAMERA_ERROR';

export interface CameraState {
    items: IATCamera[];
    checksum: string;
    count: string;
    mgmtFilteredItems: IATCamera[] | null;
    editorFilteredItems: IATCamera[];
    loading: boolean;
    error: Error | null;
    processing: boolean;
    saveSuccess: boolean;
    lastResponse: IProcessedResponse;
}

export interface CameraPayload {
    items: IATCamera[];
    checksum: string;
    count: string;
    mgmtFilteredItems: IATCamera[];
    editorFilteredItems: IATCamera[];
    error: Error | null;
    response: IProcessedResponse;
}

interface FetchCamerasBeginAction {
    type: typeof FETCH_CAMERAS_BEGIN;
    payload: CameraPayload;
}

interface FetchCameraSuccessAction {
    type: typeof FETCH_CAMERAS_SUCCESS;
    payload: CameraPayload;
}

interface FetchCameraNoChangeAction {
    type: typeof FETCH_CAMERAS_NOCHANGE;
    payload: CameraPayload;
}

interface FetchCameraFailureAction {
    type: typeof FETCH_CAMERAS_FAILURE;
    payload: CameraPayload;
}

interface UpdateAccessLevelBeginAction {
    type: typeof UPDATE_ACCESS_LEVEL_BEGIN;
    payload: CameraPayload;
}

interface UpdateAccessLevelSuccessAction {
    type: typeof UPDATE_ACCESS_LEVEL_SUCCESS;
    payload: CameraPayload;
}

interface UpdateAccessLevelClearAction {
    type: typeof UPDATE_ACCESS_LEVEL_CLEAR;
    payload: CameraPayload;
}

interface UpdateAccessLevelFailureAction {
    type: typeof UPDATE_ACCESS_LEVEL_FAILURE;
    payload: CameraPayload;
}

interface ModifyCameraInfoBeginAction {
    type: typeof MODIFY_CAMERA_INFO_BEGIN;
    payload: CameraPayload;
}

interface ModifyCameraInfoSuccessAction {
    type: typeof MODIFY_CAMERA_INFO_SUCCESS;
    payload: CameraPayload;
}

interface ModifyCameraInfoFailureAction {
    type: typeof MODIFY_CAMERA_INFO_FAILURE;
    payload: CameraPayload;
}

interface BulkUpdateBeginAction {
    type: typeof BULK_UPDATE_BEGIN;
    payload: CameraPayload;
}

interface BulkUpdateSuccessAction {
    type: typeof BULK_UPDATE_SUCCESS;
    payload: CameraPayload;
}

interface BulkUpdateClearAction {
    type: typeof BULK_UPDATE_CLEAR;
    payload: CameraPayload;
}

interface BulkUpdateFailureAction {
    type: typeof BULK_UPDATE_FAILURE;
    payload: CameraPayload;
}

interface FilterDatasourceCameraBeginAction {
    type: typeof FILTER_DATASOURCES_CAMERA_BEGIN;
    payload: CameraPayload;
}

interface FilterDatasourceCameraSuccessAction {
    type: typeof FILTER_DATASOURCES_CAMERA_SUCCESS;
    payload: CameraPayload;
}

interface FilterDatasourceCameraFailureAction {
    type: typeof FILTER_DATASOURCES_CAMERA_FAILURE;
    payload: CameraPayload;
}

interface FilterEditorCameraListBeginAction {
    type: typeof FILTER_EDITOR_CAMERA_BEGIN;
    payload: CameraPayload;
}

interface FilterEditorCameraListSuccessAction {
    type: typeof FILTER_EDITOR_CAMERA_SUCCESS;
    payload: CameraPayload;
}

interface FilterEditorCameraListFailureAction {
    type: typeof FILTER_EDITOR_CAMERA_FAILURE;
    payload: CameraPayload;
}

interface RefreshCameraListBeginAction {
    type: typeof REFRESH_CAMERA_LISTS_BEGIN;
    payload: CameraPayload;
}

interface RefreshCameraListSuccessAction {
    type: typeof REFRESH_CAMERA_LISTS_SUCCESS;
    payload: CameraPayload;
}

interface RefreshCameraListFailureAction {
    type: typeof REFRESH_CAMERA_LISTS_FAILURE;
    payload: CameraPayload;
}

interface ClearCameraErrorAction {
    type: typeof CLEAR_CAMERA_ERROR;
    payload: CameraPayload;
}

export type CameraTypes = FetchCamerasBeginAction | 
FetchCameraSuccessAction | 
FetchCameraNoChangeAction | 
FetchCameraFailureAction | 
UpdateAccessLevelBeginAction | 
UpdateAccessLevelSuccessAction |
UpdateAccessLevelClearAction |
UpdateAccessLevelFailureAction |
ModifyCameraInfoBeginAction |
ModifyCameraInfoSuccessAction |
ModifyCameraInfoFailureAction |
BulkUpdateBeginAction | 
BulkUpdateSuccessAction | 
BulkUpdateClearAction | 
BulkUpdateFailureAction |
FilterDatasourceCameraBeginAction |
FilterDatasourceCameraSuccessAction |
FilterDatasourceCameraFailureAction |
FilterEditorCameraListBeginAction |
FilterEditorCameraListSuccessAction |
FilterEditorCameraListFailureAction |
RefreshCameraListBeginAction |
RefreshCameraListSuccessAction |
RefreshCameraListFailureAction |
ClearCameraErrorAction;