// libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";
// components
import { IAVLegacyVideoBoard } from "@algo/network-manager/models/v3/video";
import DismissableModal from '../modals/DismissableModal';
import DroppableVideoItem from './DroppableVideoItem';
import {Pager} from "@algo/pager";
// store
import { AppState } from '../../../store';
import { CameraState } from '../../../store/camera/types';
import { EditorState } from '../../../store/videoboard/types';
import {
    setName, addPage,removePage, resizePage,
    save, clearEditorState, savePageState
} from '../../../store/videoboard/actions';
import { 
    DEFAULT_PAGE_INDEX 
} from '../../../utils/AppConstants';

interface OwnProps {
    id: number;
    currentPageIndex: number;
    rowSize: number;
    columnSize: number;
}

interface StateProps {
    camera: CameraState;
    editor: EditorState;
}

let mapStateToProps = (state: AppState) => {
    return {
        camera: state.camera,
        editor: state.editor
    };
}

interface DispatchProps {
    setName: typeof setName;
    addPage: typeof addPage;
    removePage: typeof removePage;
    resizePage: typeof resizePage;
    save: typeof save;
    clearEditorState: typeof clearEditorState;
    savePageState: typeof savePageState;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        setName: setName,
        addPage: addPage,
        removePage: removePage,
        resizePage: resizePage,
        save: save,
        clearEditorState: clearEditorState,
        savePageState: savePageState
    }, dispatch);
}

type VideoboardEditorProps = OwnProps & StateProps & DispatchProps;

interface VideoboardEditorState {
    currentPageIndex: number;
    rowSize: number;
    columnSize: number;
    showNoNameModal: boolean;
}

class VideoboardEditor extends React.Component<VideoboardEditorProps, VideoboardEditorState> {
    constructor(props: Readonly<VideoboardEditorProps>) {
        super(props);

        this.state = {
            currentPageIndex: props.currentPageIndex ? props.currentPageIndex : DEFAULT_PAGE_INDEX,
            rowSize: props.rowSize ? props.rowSize : 2,
            columnSize: props.columnSize ? props.columnSize : 2,
            showNoNameModal: false
        } as VideoboardEditorState;
    }

    componentDidMount() {
        ($('[data-toggle="tooltip"]') as any).tooltip();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {

        if (this.props.editor.initialized && this.state.currentPageIndex > this.props.editor.videoboard.videoPages.length) {
            this.setState((prevState, props) => {
                return {
                    currentPageIndex: 0
                };
            });
        }

        if (this.state.currentPageIndex !== prevState.currentPageIndex || this.state.rowSize !== prevState.rowSize || this.state.columnSize !== prevState.columnSize) {
            this.props.savePageState(this.state.currentPageIndex, this.state.rowSize, this.state.columnSize);
        }

        if (this.props.editor.initialized !== prevProps.editor.initialized || this.state.currentPageIndex !== prevState.currentPageIndex) {
            let videoPages = this.props.editor.videoboard.videoPages;
            let curPageIndex = this.state.currentPageIndex;

            if (
                videoPages[curPageIndex] && 
                (
                    videoPages[curPageIndex].rowSize !== prevState.rowSize || 
                    videoPages[curPageIndex].columnSize !== prevState.columnSize
                )
            ) {
                this.setState({
                    rowSize: videoPages[curPageIndex].rowSize,
                    columnSize: videoPages[curPageIndex].columnSize
                });
            }
        }
    }

    updatePageIndex = (newPageIndex: number) => {
        this.setState({
            ...this.state,
            currentPageIndex: newPageIndex,
        });
    }

    previousPage = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                currentPageIndex: (state.currentPageIndex - 1) < 0 ? 0 : (state.currentPageIndex - 1) 
            };
        });
    }

    nameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        this.props.setName(event.currentTarget.value);
    }

    rowSizeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        event.preventDefault();

        const rSize = parseInt(event.currentTarget.value);

        if (this.props.editor.videoboard.videoPages[this.state.currentPageIndex]) {
            this.props.resizePage(this.state.currentPageIndex, rSize, this.state.columnSize);
        }

        this.setState({
            rowSize: rSize
        });
    }

    columnSizeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        event.preventDefault();

        const cSize = parseInt(event.currentTarget.value);

        if (this.props.editor.videoboard.videoPages[this.state.currentPageIndex]) {
            this.props.resizePage(this.state.currentPageIndex, this.state.rowSize, cSize);
        }

        this.setState({
            columnSize: cSize
        });
    }

    addPage = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        this.props.addPage(this.state.rowSize, this.state.columnSize);

        this.setState((prevState, props) => {
            return {
                currentPageIndex: props.editor.videoboard.videoPages.length - 1
            };
        });
    }

    removePage = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (this.props.editor.videoboard.videoPages.length > 0) {
            this.previousPage();

            this.props.removePage(this.state.currentPageIndex);
        }
    }

    saveVideoboard = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        ($('[data-toggle="tooltip"]') as any).tooltip('hide');
        if (this.props.editor.videoboard.name && this.props.editor.videoboard.name !== '') {
            this.props.save();
        }
        else {
            this.setState({
                showNoNameModal: true
            });
        }
    }

    nameModalCallback = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                showNoNameModal: false
            }
        });
    }

    renderPage = (videoboard: IAVLegacyVideoBoard, currentPageIndex: number): React.ReactNode => {
        let page = videoboard.videoPages[currentPageIndex];

        if (!page) {
            return (
                <div className='av-center-alert-container'>
                    <div className='av-primary-alert'>
                        Add a page to start building your videoboard
                    </div>
                </div>
            );
        }
        else {
            let colClass = page ? (`av-grid-col-${page.columnSize}`) : '';
            let rowClass = page ? (`av-grid-row-${page.rowSize}`) : '';

            return (
                <div className={`av-stream-display ${colClass} ${rowClass}`}>
                    {page.videoItems.map((videoItem: any, index: any) => {
                        return (
                            <DroppableVideoItem
                                key={`${videoboard.id}_${page.placement}_${videoItem.placement}_${videoItem.cameraId}`}
                                videoPageLocation={page.placement}
                                itemLocation={videoItem.placement}
                                cameraId={videoItem.cameraId}
                            />
                        );
                    })}
                </div>
            );
        }
    }

    renderShowNoNameModal = (showModal: boolean): React.ReactNode => {
        if (showModal) {
            return (
                <DismissableModal
                    title='No Title Provided'
                    message='Please give this videoboard a title if you wish to save the videoboard.'
                    type='warning'
                    dismissCallback={this.nameModalCallback}
                />
            );
        }
        else {
            return null;
        }
    }

    renderEditor = (): React.ReactNode => {
        let { editor } = this.props;

        return (
            <div className='av-videoboard-editor'>
                <div className=''>
                    <div className='av-videoboard-name'>
                        <div className='name-input'>
                            <input
                                id='txtVideoboardName'
                                className={`av-videoboard-name-control`}
                                type='text'
                                value={editor.videoboard.name}
                                placeholder='YOUR VIDEOBOARD TITLE...'
                                onChange={this.nameChange}
                            />
                        </div>
                        <div className='save-btn'>
                            <button
                                className='btn btn-outline-primary'
                                type='button'
                                data-toggle='tooltip'
                                title='Save this videoboard'
                                onClick={this.saveVideoboard}
                                disabled={editor.processing}
                            >
                                <span className={`save-icon ${editor.processing ? 'saving' : ''}`}></span> Save Videoboard
                            </button>
                        </div>
                    </div>
                    <div className='av-videoboard-toolbar'>
                        <div className='d-flex flex-row no-gutters'>
                            <div className='col-7'>
                                <div className='row'>
                                    <div className='av-toolbar-input-group' data-toggle='tooltip' title='Change row size'>
                                        <div className="input-group-prepend">
                                            <label className="input-group-text" htmlFor="ddlRowSize">
                                                <i className="fas fa-table"></i>
                                            </label>
                                        </div>
                                        <select 
                                            className="custom-select" id="ddlRowSize" 
                                            value={this.state.rowSize} onChange={this.rowSizeChange}
                                        >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                        </select>
                                    </div>
                                    <div className="av-toolbar-input-group" data-toggle='tooltip' title='Change column size'>
                                        <div className="input-group-prepend">
                                            <label className="input-group-text" htmlFor="ddlColumnSize">
                                                <i className="fas fa-columns"></i>
                                            </label>
                                        </div>
                                        <select 
                                            className="custom-select" id="ddlColumnSize" 
                                            value={this.state.columnSize} onChange={this.columnSizeChange}
                                        >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-5 text-right'>
                                <div className='av-toolbar-btn-group'>
                                    <button
                                        className="btn btn-outline-danger"
                                        type="button"
                                        data-toggle='tooltip'
                                        title='Remove the current page from the videoboard'
                                        onClick={this.removePage}
                                    >
                                        <i className="fas fa-trash-alt"></i><span className='sr-only'>Remove current page</span>
                                    </button>
                                    <button
                                        className="btn btn-outline-primary"
                                        type="button"
                                        data-toggle='tooltip'
                                        title='Add a page to the videoboard'
                                        onClick={this.addPage}
                                    >
                                        <i className="fas fa-plus"></i><span className='sr-only'>Add page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderPage(editor.videoboard, this.state.currentPageIndex)}
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "8px 0 0 0"}}>
                    <Pager 
                        pageIndex={this.state.currentPageIndex} 
                        pageCount={editor.videoboard.videoPages.length} 
                        updatePageIndex={this.updatePageIndex}
                        minimalNav={true} size={"xs"}
                        llipsisThreshold={5} ellipsisOffset={1}
                    />
                </div>
                {this.renderShowNoNameModal(this.state.showNoNameModal)}
            </div>
        );
    }

    render() {
        if (!this.props.editor.initialized) {
            return (
                <div className='page-loader-container'>
                    <div className='page-loader'>
                        <i className="fas fa-circle-notch fa-spin"></i>
                    </div>
                </div>
            );
        }
        else {
            return this.renderEditor();
        }
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(VideoboardEditor);