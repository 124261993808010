// libraries
import * as React from 'react';

/* <Description>
 *      
 *      A rip-out from the SmartSearch Component. DummySearch looks exactly like SmartSearch but provides absolutely no functional logic internally. 
 *      DummySearch simply acts as a middle-man between user and parent to pass up user search text. 
 *      
 *      Expects:
 *      
 *          an img object to display the input-group-append icon of the form:
 *              {
 *                  src: Typescript imported image object,
 *                  alt: string,
 *                  width: number,
 *                  height: number,
 *              }
 *              
 *          a callbackAction to pass up the user input event to parent of the form: 
 *              function(event: any){}
 *      
 *  </Description>
 */

interface IProps {
    img: any,                           //the text-group-append img as an image object
    callbackAction: any;                //a function to call when the search logic is complete. the function should expect a SmartSearch state object as argument
}

interface IState {
    searchTerm: string;                 //the string value found in the search bar value field; used to update the component to show user input in real time
}

export class DummySearch extends React.Component<IProps, IState> {

    constructor(props: any) {   //get started with a default state
        super(props);
        this.state = {
            searchTerm: "",
        }
    }

    /*************************************************************************************************************************************************
                                                                        Main Render    
    ************************************************************************************************************************************************/

    render() {
        return (
            <div className="input-group dummy-search">
                <input 
                    type="text" className="form-control" value={this.state.searchTerm}
                    placeholder="Search..." aria-label="Recipient's username" 
                    aria-describedby="basic-addon2" onChange={this.handleSearchChange}
                />
                <div className="input-group-append" onClick={this.handleSearchClick}>
                    <button 
                        className="input-group-button btn btn-outline-primary" 
                        onClick={this.handleSearchClick}
                    >
                        <i className='fas fa-search'></i>
                    </button>
                </div>
            </div>
        );
    }


    /*************************************************************************************************************************************************
                                                                        Event Handlers    
    ************************************************************************************************************************************************/

    /* 
     *  handles user changes on the input element representing the search bar string
     */
    handleSearchChange = (event: any) => {                                          //When the search bar value is changed, take that event and...
        event.persist();
        this.setState({ ...this.state, searchTerm: event.target.value });           //update the searchTerm in state so that the view knows about the user input
        this.props.callbackAction(event);
        return null;
    }

    /*
     *  handles user clicks on the text-group-append span representing the search button
    */
    handleSearchClick = (event: any) => {                                           //When the search icon is clicked...
        return null;
    }

    /*
     *  handles user Enter key press on the text-group-append span representing the search button 
     */
    handleSearchKeyUp = (event: any) => {
        if (event.key === 'Enter') {                                                //When the Enter key is pressed and released...
            this.handleSearchClick(event);                                          //call the same function that is used to handle clicking the search button
        }
    }
}

export default DummySearch;