// libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
import { CSSTransition } from 'react-transition-group';
// components
import Sidebar from '../base/layout/Sidebar';
import Content from '../base/layout/Content';
import CameraSelector from '../base/editor/CameraSelector';
import VideoboardEditor from '../base/editor/VideoboardEditor';
import VideoboardSelector from '../base/VideoboardSelector';
import Videoboard from '../base/display/Videoboard';
import ConfirmationModal from '../base/modals/ConfirmationModal';
// store
import { AppState } from '../../store';
import { EditorState } from '../../store/videoboard/types';
import { User } from 'oidc-client';
import { getCameras } from '../../store/camera/actions';
import { 
    clearEditorState, getVideoboardList, loadVideoboard, 
    initializeVideoboardEditor, deleteVideoboard
} from '../../store/videoboard/actions';
// constants
import { CAMERA_REFRESH_INTERVAL } from '../../utils/AppConstants';

interface StateProps {
    user: User | undefined;
    editor: EditorState;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user,
        editor: state.editor
    };
}

interface DispatchProps {
    clearEditorState: typeof clearEditorState;
    getCameras: typeof getCameras;
    getVideoboardList: typeof getVideoboardList;
    loadVideoboard: typeof loadVideoboard;
    initializeVideoboardEditor: typeof initializeVideoboardEditor;
    deleteVideoboard: typeof deleteVideoboard;
}

let mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return bindActionCreators({
        clearEditorState: clearEditorState,
        getCameras: getCameras,
        getVideoboardList: getVideoboardList,
        loadVideoboard: loadVideoboard,
        initializeVideoboardEditor: initializeVideoboardEditor,
        deleteVideoboard: deleteVideoboard
    }, dispatch);
}

type EditorPageProps = StateProps & DispatchProps & RouteComponentProps<any>;

interface EditorPageState {
    displayMode: boolean;
    videoboardId: number;
    showDeleteModal: boolean;
}

class Editor extends React.Component<EditorPageProps, EditorPageState> {
    cameraRefresher: NodeJS.Timeout;

    constructor(props: Readonly<EditorPageProps>) {        
        super(props);

        this.cameraRefresher = setInterval(this.fetchCameras, CAMERA_REFRESH_INTERVAL);

        this.state = {
            displayMode: false,
            videoboardId: this.props.match.params.id | 0,
            showDeleteModal: false
        } as EditorPageState;
    }

    componentDidMount() {
        this.props.getVideoboardList();

        if (this.state.videoboardId === 0) {
            this.props.initializeVideoboardEditor();
        }
        else {
            this.props.loadVideoboard(this.state.videoboardId);
        }
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.state.videoboardId !== prevState.videoboardId) {
            if (this.state.videoboardId === 0) {
                this.props.initializeVideoboardEditor();
            }
            else {
                this.props.loadVideoboard(this.state.videoboardId);
            }
        }

        if (this.props.editor.processing !== prevProps.editor.processing && !this.props.editor.processing) {
            this.props.getVideoboardList();
        }
    }

    componentWillUnmount() {
        clearInterval(this.cameraRefresher);
        this.props.initializeVideoboardEditor();
    }

    fetchCameras = (): void => {
        this.props.getCameras();
    }

    setToEditor = (event: React.MouseEvent<HTMLLabelElement>): void => {
        if (this.props.editor.initialized) {
            this.setState((state, props) => {
                return {
                    ...state,
                    displayMode: false           
                };
            });
        }
    }

    setToDisplay = (event: React.MouseEvent<HTMLLabelElement>) => {
        if (this.props.editor.initialized) {
            this.setState((state, props) => {
                return {
                    ...state,
                    displayMode: true           
                };
            });
        }
    }

    videoboardSelectorChange = (id: number):void => {
        this.props.clearEditorState();

        this.setState((state, props) => {
            return {
                ...state,
                videoboardId: id,
                displayMode: false            
            };
        });
    }

    newVideoboard = (): void => {
        this.props.initializeVideoboardEditor();

        this.setState((state, props) => {
            return {
                ...state,
                videoboardId: 0,
                displayMode: false            
            };
        });
    }

    removeVideoboard = (id: number): void => {
        this.setState((state, props) => {
            return {
                ...state,
                showDeleteModal: true
            };
        });
    }

    deleteDismissCallback = (): void  => {
        this.setState((state, props) => {
            return {
                ...state,
                showDeleteModal: false
            };
        });
    }

    deleteConfirmCallback = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                showDeleteModal: false,
                displayMode: false,
                videoboardId: 0
            };
        });

        this.props.deleteVideoboard(this.props.editor.videoboard);
    }

    render() {
        let { editor } = this.props;

        let content = this.state.displayMode ? (
            <Videoboard
                key={`${this.state.videoboardId}_display`}
                board={editor.videoboard}
                currentPageIndex={editor.currentPageIndex}
            />
        ) : (
                <VideoboardEditor
                    key={`${this.state.videoboardId}_editor`}
                    id={this.state.videoboardId}
                    currentPageIndex={editor.currentPageIndex}
                    rowSize={editor.rowSize}
                    columnSize={editor.columnSize}
                />
            );

        let deleteModal = this.state.showDeleteModal ? (
            <ConfirmationModal
                type={`warning`}
                title={`Delete ${editor.videoboard.name}?`}
                message={`Once deleted the videoboard will no longer be available.`}
                dismissCallback={this.deleteDismissCallback}
                confirmCallback={this.deleteConfirmCallback}
            />) : (null);

        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={true}>

                        <h1 className='sr-only'>
                            Videoboard Editor
                        </h1>
                        <div className='av-editor-tabs' data-toggle='buttons'>
                            <label className={`btn btn-outline-primary w-100 ${!this.state.displayMode ? 'active' : ''} ${!editor.initialized ? 'disabled' : ''}`} onClick={this.setToEditor}>
                                <input type="radio" name="options" id="editorOption" autoComplete='off' defaultChecked={!this.state.displayMode} /> Edit
                            </label>
                            <label className={`btn btn-outline-primary w-100 ${this.state.displayMode ? 'active' : ''} ${!editor.initialized ? 'disabled' : ''}`} onClick={this.setToDisplay}>
                                <input type="radio" name="options" id="displayOption" autoComplete='off' defaultChecked={this.state.displayMode} /> View
                            </label>
                        </div>
                        <div className='av-editor-content'>
                            <CSSTransition classNames='editor' timeout={{ enter: 200, exit: 100 }}>
                                {content}
                            </CSSTransition>
                        </div>
                    </Content>
                    <Sidebar>
                        <VideoboardSelector
                            selectedValue={this.state.videoboardId}
                            videoboards={editor.list}
                            onChangeCallback={this.videoboardSelectorChange}
                            onAddCallback={this.newVideoboard}
                            onRemoveCallback={this.removeVideoboard}
                            disabled={editor.loading || editor.processing}
                        />
                        <CameraSelector />
                    </Sidebar>
                </div>
                {deleteModal}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(Editor));