// libraries
import * as React from 'react';

export default class Loading extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className='av-loader-content'>
                <div className='page-loader-container'>
                    <div className='page-loader'>
                        <i className="fas fa-circle-notch fa-spin"></i>
                    </div>
                </div>
            </div>
        );
    }
}