// liraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { Action } from "redux";
import { getAccessToken } from "../../utils/data/getAccessToken";
// interfaces & models
import * as TYPES from "./types";
//constants
import { CUR_API_VERSION, CUR_API_ENPOINTS } from "../api-version-constants";
import { 
    IProcessedResponse,
    IUserNetworkManager, UserNetworkManager
} from "@algo/network-manager/managers/v3";
declare var __API_URL__: string;
const apiUrlProfile: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENPOINTS(CUR_API_VERSION).users}`;


/*
Get User Profile
*/
function fetchProfileBegin(): TYPES.ProfileTypes {
    return {
        type: TYPES.FETCH_PROFILE_BEGIN,
        payload: {} as TYPES.ProfilePayload
    };
}

function fetchProfileSuccess(response: IProcessedResponse): TYPES.ProfileTypes {
    return {
        type: TYPES.FETCH_PROFILE_SUCCESS,
        payload: {
            response: response,
            error: null
        } as TYPES.ProfilePayload
    };
}

function fetchProfileFailure(error: Error): TYPES.ProfileTypes {
    return {
        type: TYPES.FETCH_PROFILE_FAILURE,
        payload: {
            error: error
        } as TYPES.ProfilePayload
    };
}

export let loadUserProfile = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().profile.loading) {
        dispatch(fetchProfileBegin());

        getAccessToken().then(
            (token) => {
                let manager: IUserNetworkManager = new UserNetworkManager(apiUrlProfile);
                manager.setAccessToken(token);

                // uses processedFetch rather than getData (User api has no head calls)
                manager.getProfile()
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error) 
                            dispatch(fetchProfileFailure(response.error))
                        else
                            dispatch(fetchProfileSuccess(response))
                    }
                ).catch(
                    (err: Error) => {
                        dispatch(fetchProfileFailure(err))
                    }
                )
            }
        ).catch(
            (error: Error) => {
                dispatch(fetchProfileFailure(error))
            }
        )
    }
}

export let updateUserProfile = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().profile.initialized) {
        dispatch(fetchProfileBegin());

        getAccessToken().then(
            (token) => {
                let manager: IUserNetworkManager = new UserNetworkManager(apiUrlProfile);
                manager.setAccessToken(token);

                // uses processedFetch rather than getData (User api has no head calls)
                manager.getProfile()
                .then(
                    (processed: IProcessedResponse) => {
                        dispatch(fetchProfileSuccess(processed))
                    }
                ).catch(
                    (err: Error) => {
                        dispatch(fetchProfileFailure(err))
                    }
                )
            }
        ).catch(
            (error: Error) => {
                dispatch(fetchProfileFailure(error))
            }
        )
    }
}

/*
Unload User Profile
*/

function unloadProfileState(): TYPES.ProfileTypes {
    return {
        type: TYPES.UNLOAD_PROFILE,
        payload: {} as TYPES.ProfilePayload
    };
}

export let unloadUserProfile = (

): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch(unloadProfileState());
}

/*
Clear User Profile Error
*/

function clearUserProfileErrorState(): TYPES.ProfileTypes {
    return {
        type: TYPES.CLEAR_PROFILE_ERROR,
        payload: {} as TYPES.ProfilePayload
    };
}

export let clearUserProfileError = (

): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch(clearUserProfileErrorState());
}