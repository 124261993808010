import * as React from 'react';
import $ from "jquery";

interface ErrorModalProps {
    dismissCallback: (() => void);
    title?: string;
    message?: string;
    error?: Error;
}

interface ErrorModalState {
    instanceId: number;
}

export default class ErrorModal extends React.Component<ErrorModalProps, ErrorModalState> {
    modalRef: React.RefObject<HTMLDivElement>;

    constructor(props: Readonly<ErrorModalProps>) {
        super(props);

        this.modalRef = React.createRef<HTMLDivElement>();

        this.state = {
            instanceId: Math.random()
        } as ErrorModalState;
    }

    componentDidMount() {
        this.modalRef.current &&
            $(this.modalRef.current).modal('show');
    }

    dismiss = (event: React.MouseEvent<HTMLElement>): void => {
        const { dismissCallback } = this.props;

        event.preventDefault();

        this.modalRef.current &&
            $(this.modalRef.current).modal('hide');

        dismissCallback();
    }

    render() {
        let { title, message, error } = this.props;

        var formattedMessage: string | undefined;

        if (error) {
            formattedMessage = error.message;
        }
        else if (message) {
            formattedMessage = title;
        }
        else {
            formattedMessage = 'There was an error';
        }

        var formattedTitle: string;

        if (error) {
            formattedTitle = error.name;
        }
        else if (title) {
            formattedTitle = title;
        }
        else {
            formattedTitle = 'General Exception';
        }

        return (
            <div 
                className='modal fade' ref={this.modalRef} id={`errorModal_${this.state.instanceId}`} 
                tabIndex={-1} role='dialog' data-keyboard='false' data-backdrop='static' 
                aria-labelledby={`errorModalTitle_${this.state.instanceId}`} aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id={`errorModalTitle_${this.state.instanceId}`}>
                                {formattedTitle}
                            </h5>
                        </div>
                        <div className='modal-body'>
                            <div className='alert alert-danger'>
                                <strong>Error Message:</strong> {formattedMessage}
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button 
                                className='btn btn-block btn-primary' 
                                onClick={this.dismiss}
                            >
                                <i className='fa fa-times-circle'></i> 
                                Dismiss
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}