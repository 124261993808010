import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { IAVLegacyVideoBoard } from "@algo/network-manager/models/v3/video";

export interface EditorState {
    initialized: boolean;
    processing: boolean;
    loading: boolean;
    error: Error | null;
    list: IAVLegacyVideoBoard[];
    videoboard: IAVLegacyVideoBoard;
    currentPageIndex: number;
    rowSize: number;
    columnSize: number;
}

export interface EditorPayload {
    videoboard: IAVLegacyVideoBoard;
    list: IAVLegacyVideoBoard[];
    currentPageIndex: number;
    rowSize: number;
    columnSize: number;
    error: Error | null;
    response: IProcessedResponse;
}

export let INITIALIZE_VIDEOBOARD_SUCCESS = 'INITIALIZE_VIDEOBOARD_SUCCESS';
export let LOAD_VIDEOBOARD_SUCCESS = 'LOAD_VIDEOBOARD_SUCCESS';
export let INITIALIZE_VIDEOBOARD_FAILURE = 'INITIALIZE_VIDEOBOARD_FAILURE';

interface InitializeVideoboardSuccessAction {
    type: typeof INITIALIZE_VIDEOBOARD_SUCCESS,
    payload: EditorPayload;
}

interface LoadVideoboardSuccessAction {
    type: typeof LOAD_VIDEOBOARD_SUCCESS;
    payload: EditorPayload;
}

interface InitializeVideoboardFailureAction {
    type: typeof INITIALIZE_VIDEOBOARD_FAILURE;
    payload: EditorPayload;
}

export let SET_NAME_SUCCESS = 'SET_NAME_SUCCESS';
export let SET_NAME_FAILURE = 'SET_NAME_FAILURE';

interface SetNameSuccessAction {
    type: typeof SET_NAME_SUCCESS;
    payload: EditorPayload;
}

interface SetNameFailureAction {
    type: typeof SET_NAME_FAILURE;
    payload: EditorPayload;
}

export let SET_CAMERA_ITEM_SUCCESS = 'SET_CAMERA_ITEM_SUCCESS';
export let SET_CAMERA_ITEM_FAILURE = 'SET_CAMERA_ITEM_FAILURE';

interface SetCameraItemSuccessAction {
    type: typeof SET_CAMERA_ITEM_SUCCESS;
    payload: EditorPayload;
}

interface SetCameraItemFailureAction {
    type: typeof SET_CAMERA_ITEM_FAILURE;
    payload: EditorPayload;
}

export let REMOVE_CAMERA_ITEM_SUCCESS = 'REMOVE_CAMERA_ITEM_SUCCESS';
export let REMOVE_CAMERA_ITEM_FAILURE = 'REMOVE_CAMERA_ITEM_FAILURE';

interface RemoveCameraItemSuccessAction {
    type: typeof REMOVE_CAMERA_ITEM_SUCCESS;
    payload: EditorPayload;
}

interface RemoveCameraItemFailureAction {
    type: typeof REMOVE_CAMERA_ITEM_FAILURE;
    payload: EditorPayload;
}

export let ADD_PAGE_SUCCESS = 'ADD_PAGE_SUCCESS';
export let ADD_PAGE_FAILURE = 'ADD_PAGE_FAILURE';

interface AddPageSuccessAction {
    type: typeof ADD_PAGE_SUCCESS;
    payload: EditorPayload;
}

interface AddPageFailureAction {
    type: typeof ADD_PAGE_FAILURE;
    payload: EditorPayload;
}

export let REMOVE_PAGE_SUCCESS = 'REMOVE_PAGE_SUCCESS';
export let REMOVE_PAGE_FAILURE = 'REMOVE_PAGE_FAILURE';

interface RemovePageSuccessAction {
    type: typeof REMOVE_PAGE_SUCCESS;
    payload: EditorPayload;
}

interface RemovePageFailureAction {
    type: typeof REMOVE_PAGE_FAILURE;
    payload: EditorPayload;
}

export let RESIZE_PAGE_SUCCESS = 'RESIZE_PAGE_SUCCESS';
export let RESIZE_PAGE_FAILURE = 'RESIZE_PAGE_FAILURE';

interface ResizePageSuccessAction {
    type: typeof RESIZE_PAGE_SUCCESS;
    payload: EditorPayload;
}

interface ResizePageFailureAction {
    type: typeof RESIZE_PAGE_FAILURE;
    payload: EditorPayload;
}

export let SAVE_VIDEOBOARD_BEGIN = 'SAVE_VIDEOBOARD_BEGIN';
export let SAVE_VIDEOBOARD_SUCCESS = 'SAVE_VIDEOBOARD_SUCCESS';
export let SAVE_VIDEOBOARD_FAILURE = 'SAVE_VIDEOBOARD_FAILURE';

interface SaveVideoboardBeginAction {
    type: typeof SAVE_VIDEOBOARD_BEGIN;
    payload: EditorPayload;
}

interface SaveVideoboardSuccessAction {
    type: typeof SAVE_VIDEOBOARD_SUCCESS;
    payload: EditorPayload;
}

interface SaveVideoboardFailureAction {
    type: typeof SAVE_VIDEOBOARD_FAILURE;
    payload: EditorPayload;
}

export let SAVE_PAGE_STATE = 'SAVE_PAGE_STATE';

interface SavePageStateAction {
    type: typeof SAVE_PAGE_STATE;
    payload: EditorPayload;
}

export let CLEAR_EDITOR_STATE = 'CLEAR_EDITOR_STATE';

interface ClearEditorStateAction {
    type: typeof CLEAR_EDITOR_STATE;
    payload: EditorPayload;
}

export let CLEAR_EDITOR_ERROR = 'CLEAR_EDITOR_ERROR';

interface ClearEditorErrorAction {
    type: typeof CLEAR_EDITOR_ERROR;
    payload: EditorPayload;
}

export let GET_VIDEOBOARD_LIST_BEGIN = 'GET_VIDEOBOARD_LIST_BEGIN';
export let GET_VIDEOBOARD_LIST_SUCCESS = 'GET_VIDEOBOARD_LIST_SUCCESS';
export let GET_VIDEOBOARD_LIST_FAILURE = 'GET_VIDEOBOARD_LIST_FAILURE';

interface GetVideoboardListBeginAction {
    type: typeof GET_VIDEOBOARD_LIST_BEGIN;
    payload: EditorPayload;
}

interface GetVideoboardListSuccessAction {
    type: typeof GET_VIDEOBOARD_LIST_SUCCESS;
    payload: EditorPayload;
}

interface GetVideoboardListFailureAction {
    type: typeof GET_VIDEOBOARD_LIST_FAILURE;
    payload: EditorPayload;
}

export let DELETE_VIDEOBOARD_BEGIN = 'DELETE_VIDEOBOARD_BEGIN';
export let DELETE_VIDEOBOARD_SUCCESS = 'DELETE_VIDEOBOARD_SUCCESS';
export let DELETE_VIDEOBOARD_FAILURE = 'DELETE_VIDEOBOARD_FAILURE';

interface DeleteVideoboardBeginAction {
    type: typeof DELETE_VIDEOBOARD_BEGIN;
    payload: EditorPayload;
}

interface DeleteVideoboardSuccessAction {
    type: typeof DELETE_VIDEOBOARD_SUCCESS;
    payload: EditorPayload;
}

interface DeleteVideoboardFailureAction {
    type: typeof DELETE_VIDEOBOARD_FAILURE;
    payload: EditorPayload;
}

export type EditorTypes = InitializeVideoboardSuccessAction |
LoadVideoboardSuccessAction |
InitializeVideoboardFailureAction |
SetNameSuccessAction |
SetNameFailureAction |
SetCameraItemSuccessAction |
SetCameraItemFailureAction |
RemoveCameraItemSuccessAction |
RemoveCameraItemFailureAction |
AddPageSuccessAction |
AddPageFailureAction |
RemovePageSuccessAction |
RemovePageFailureAction |
ResizePageSuccessAction |
ResizePageFailureAction |
SaveVideoboardBeginAction |
SaveVideoboardSuccessAction | 
SaveVideoboardFailureAction |
SavePageStateAction |
ClearEditorStateAction |
ClearEditorErrorAction |
GetVideoboardListBeginAction |
GetVideoboardListSuccessAction | 
GetVideoboardListFailureAction;