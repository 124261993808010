// libraries
import * as React from 'react';
// models & interfaces
import { IATCamera } from '@algo/network-manager/models/v3';
// constants
import { ASPECT_RATIO_16_10 } from '../../../utils/AppConstants';

interface DraggableCameraProps {
    item: IATCamera;
    className?: string;
}

interface DraggableCameraState {
    imageUrl: string;
    loadingImage: boolean;
    snapshotAspectRatioClass: string;
}

export default class DraggableCamera extends React.Component<DraggableCameraProps, DraggableCameraState> {
    snapshotRef: React.RefObject<HTMLImageElement>;

    constructor(props: Readonly<DraggableCameraProps>) {
        super(props);

        this.state = {
            imageUrl: '',
            loadingImage: true,
            snapshotAspectRatioClass: 'fill',
        } as DraggableCameraState;

        this.snapshotRef = React.createRef();
    }

    componentDidMount() {
        this.snapshotRef.current &&
            this.snapshotRef.current.addEventListener('load', this.snapshotImageLoaded);
    }

    componentWillUnmount() {
        this.snapshotRef.current &&
            this.snapshotRef.current.removeEventListener('load', this.snapshotImageLoaded);
    }

    snapshotImageLoaded = (event: Event): void => {

        let snapshot = this.snapshotRef.current;

        if (snapshot) {

            let naturalAspectRatio: number = 
                snapshot.naturalWidth / snapshot.naturalHeight;
                
            let aspectRatioClass: string = 'fill';

            if (naturalAspectRatio === ASPECT_RATIO_16_10)
                aspectRatioClass = 'contain';

            this.setState({
                ...this.state,
                snapshotAspectRatioClass: aspectRatioClass
            });
        }
    }

    dragStartHandler = (event: React.DragEvent<HTMLDivElement>): void => {
        event.persist();
        event.dataTransfer.setData("text/plain", event.currentTarget.id);
    }

    render() {
        const { item, className } = this.props;

        return (
            <div 
                id={item.id.toString()} className={`av-camera draggable ${className}`} 
                draggable={true} onDragStart={this.dragStartHandler}
            >
                <div className='av-camera-content'>
                    <div className="card av-camera-card">
                        <div className="av-camera-header">
                            <div className='av-camera-title'>
                                {item.location.displayRouteDesignator}
                            </div>
                            <div className='av-camera-subtitle'>
                                {item.location.crossStreet}
                            </div>
                        </div>
                        <div className='av-camera-img'>
                            <img 
                                ref={this.snapshotRef} src={item.imageUrl} 
                                className={`av-camera-img ${this.state.snapshotAspectRatioClass}`} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
