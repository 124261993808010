import * as React from 'react';
import {ThemeProvider} from "styled-components";
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { BrowserRouter } from 'react-router-dom';

import { ThemeContext } from "@caps-mobile/algo-theme";
import { EThemeOptions, getTheme } from "@caps-mobile/algo-theme";

import store from './store';
import userManager from './utils/userManager';
import Template from './components/Template';

export type IProps = {

};

export const App: React.FC<IProps> = (props) => {

    const [themeMode, setThemeMode] = 
        React.useState<EThemeOptions>(EThemeOptions.Light);

    const [themeObject, setThemeObject] =
        React.useState<any>(getTheme(themeMode));

    return (
        <Provider store={store}>
            <OidcProvider store={store} userManager={userManager}>
                <ThemeProvider theme={themeObject}>
                    <ThemeContext.Provider value={themeObject}>
                        <BrowserRouter>
                            <Template />
                        </BrowserRouter>
                    </ThemeContext.Provider>
                </ThemeProvider>
            </OidcProvider>
        </Provider>    
    );

};

export default App;