//libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
import videojs from 'video.js';
import { nuon } from "@caps-mobile/common-lib";
// interfaces & models
import { 
    IAAUserAuthorizationProfile 
} from '@algo/network-manager/models/v3/admin';
import { 
    EATRegion, EATStreamAccessLevel, IATCamera
} from "@algo/network-manager/models/v3";
// components
import Content from '../base/layout/Content';
import Camera from '../base/cameramgmt/Camera';
import Loading from '../pages/Loading';
import SelectionRow from '../base/SelectionRow';
import DummySearch from '../base/DummySearch';
import AccessLevelLegendModal from '../base/modals/AccessLevelLegendModal';
//store
import { AppState } from "../../store";
import { ProfileState } from "../../store/profile/types";
import { CameraState } from "../../store/camera/types";
import { DatasourceState } from "../../store/datasource/types";
import { User } from "oidc-client";
import { 
    getCameras, getCamerasForManagement, 
    refreshCameraLists, clearCameraStoreError 
} from '../../store/camera/actions';
import { loadDatasources } from '../../store/datasource/actions';

//constants
import { CAMERA_REFRESH_INTERVAL } from '../../utils/AppConstants';
import {
    MEDIA_STREAM_ACCESS,
    FIRST_RESPONDER_STREAM_ACCESS,
    PRIVATE_SECTOR_STREAM_ACCESS,
    INTERNAL_STREAM_ACCESS,
    UNLIMITED_STREAMING
} from '../../models/Privileges';
import {STREAM_TIMEOUT_INTERVAL} from '../../utils/AppConstants';

//image constants
import {CAMERA_MANAGEMENT as PAGE_IMAGES} from '../../constants/imageObjects';
import { videojsHtml5 } from "../../constants/videojs-html5";

const videoJSConfig = {
    html5: videojsHtml5
};


interface StateProps {
    user: User | undefined;
    profile: ProfileState;
    camera: CameraState;
    datasource: DatasourceState;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user,
        profile: state.profile,
        camera: state.camera,
        datasource: state.datasource
    };
}

interface DispatchProps {
    loadDatasources: typeof loadDatasources;
    getCameras: typeof getCameras;
    getCamerasForManagement: typeof getCamerasForManagement;
    refreshCameraLists: typeof refreshCameraLists;
    clearCameraStoreError: typeof clearCameraStoreError;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loadDatasources: loadDatasources,
        getCameras: getCameras,
        getCamerasForManagement: getCamerasForManagement,
        refreshCameraLists: refreshCameraLists,
        clearCameraStoreError: clearCameraStoreError
    }, dispatch);
}

type CameraManagementProps = 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface CameraManagementState {
    selectedRegion: EATRegion;
    query: string;
    showLegendModal: boolean;

    // view livestream modal
    viewingLivestream: boolean;
    livestreamItem: IATCamera | null;
    isFullscreen: boolean;
}

class CameraManagement extends React.Component<CameraManagementProps, CameraManagementState> {
    
    cameraRefresher: NodeJS.Timeout | null;
    pageRefresher: NodeJS.Timeout | null;
    player: videojs.Player | null;
    streamNodeRef: React.RefObject<HTMLVideoElement>;
    modalRef: React.RefObject<HTMLDivElement>;

    constructor(props: Readonly<CameraManagementProps>) {
        super(props);

        this.pageRefresher = null;
        this.player = null;

        this.streamNodeRef = React.createRef();
        this.modalRef = React.createRef();

        this.fetchCameras();
        this.props.loadDatasources();

        this.cameraRefresher = 
            setInterval(this.fetchCameras, CAMERA_REFRESH_INTERVAL);

        this.state = {
            viewingLivestream: false,
            isFullscreen: false,
            selectedRegion: EATRegion.ALDOT,
            query: '',
            showLegendModal: false
        } as CameraManagementState;

    }

    camerasNotBusy(){
        return (
            !this.props.camera.loading &&
            !this.props.camera.processing
        );
    }

    headersChange(prevProps: CameraManagementProps){
        let curChecksum: string = this.props.camera.checksum;
        let curCount: string = this.props.camera.count;

        let prevChecksum: string = prevProps.camera.checksum;
        let prevCount: string = prevProps.camera.count;
        
        return (
            (curChecksum !== prevChecksum) ||
            (curCount !== prevCount)
        );
    }

    componentWillUnmount() {
        this.disposeVideoJS();

        this.props.getCamerasForManagement(EATRegion.Unknown);

        if (this.pageRefresher) {
            clearInterval(this.pageRefresher);
        }

        this.cameraRefresher &&
            clearInterval(this.cameraRefresher);
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.streamNodeRef.current)
            this.player = videojs(this.streamNodeRef.current, videoJSConfig);

        if (this.state.viewingLivestream) {
            this.modalRef.current &&
                ($(this.modalRef.current) as any).modal('show');

            if (this.streamNodeRef.current)
                this.player = videojs(this.streamNodeRef.current, videoJSConfig);

            videojs.log.level('off');

            this.player?.play();
        }

        if ( 
            this.camerasNotBusy() && 
            ( this.headersChange(prevProps) || !this.props.camera.mgmtFilteredItems)
        ){
            this.props.getCamerasForManagement(this.state.selectedRegion, this.state.query);
        }

        if (this.state.selectedRegion !== prevState.selectedRegion) {
            this.props.getCamerasForManagement(this.state.selectedRegion, this.state.query);
        }
        else if (this.state.query !== prevState.query) {
            this.props.getCamerasForManagement(this.state.selectedRegion, this.state.query);
        }
        else if (
            (this.props.camera.mgmtFilteredItems?.length === 0) &&                               //you suddenly have 0 mgmtFilteredItems...
            (prevProps.camera.mgmtFilteredItems.length > 0) &&                                  //but last time you have some...
            (this.state.query === prevState.query) &&                                           //and the query didn't change...
            (this.state.selectedRegion === prevState.selectedRegion))                           //and the datasource stayed the same...
            
            //then you need to start another camera get, because you just got bamboozled by a previous ComponentWillUnmount call to wipe the cameras
            {this.props.getCamerasForManagement(this.state.selectedRegion, this.state.query);}
    }

    disposeVideoJS = () => {
        if (this.player){ 
            try {
                if (!this.player.isDisposed)
                    this.player.pause();
                    this.player.ended();
                    this.player.dispose();
                this.player = null;
            }
            catch(e){
                console.error(`Video.js Error: `, e);
            }
        }
    }

    fetchCameras = (): void => {
        if (!this.props.camera.processing) {
            this.props.getCameras();
        }
    }

    datasourceSelectorChange = (region: EATRegion): void => {
        this.setState((state, props) => {
            return {
                ...state,
                selectedRegion: region
            };
        });
    }

    datasourceSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        
        this.setState((state, props) => {
            return {
                ...state,
                query: event.target.value
            };
        });
    }

    showLegendModal = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                showLegendModal: true
            };
        });
    };

    errorModalDismissCallback = (): void => {
        this.props.clearCameraStoreError();

        this.props.history.push('/cameras');
    }

    legendModalDismissCallback = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                showLegendModal: false
            };
        });
    }

    reloadPage = (): void => {
        window.location.reload();
    }

    viewLivestreamClick = (item: IATCamera) => {
        if (!this.props.profile.userProfile.hasPrivilege(UNLIMITED_STREAMING)) {
            this.pageRefresher = setInterval(this.reloadPage, STREAM_TIMEOUT_INTERVAL);
        }

        this.setState({
            viewingLivestream: true,
            livestreamItem: item
        });
    }

    closeLivestreamClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        this.modalRef.current &&
            ($(this.modalRef.current) as any).modal('hide');

        this.disposeVideoJS();

        this.setState({
            viewingLivestream: false,
            livestreamItem: null
        });

        if (!this.props.profile.userProfile.hasPrivilege(UNLIMITED_STREAMING)) {
            this.pageRefresher && 
                clearInterval(this.pageRefresher);
        }
    }

    toggleFullscreen = (event: React.MouseEvent<HTMLVideoElement>): void => {
        event.preventDefault();

        if (this.state.isFullscreen) {
            this.player?.exitFullscreen();
        }
        else {
            this.player?.requestFullscreen();
        }

        this.setState((state, props) => {
            return {
                isFullscreen: !state.isFullscreen
            };
        });
    }

    isAvailableToUser = (
        item?: IATCamera | null, 
        user?: User | null, 
        userProfile?: IAAUserAuthorizationProfile | null
    ) => {
        if (!item || !user || !userProfile) return false;

        let isNonExpiredUser: boolean = 
            nuon(user) && !user.expired;
        let hasAldot: boolean = 
            userProfile.hasPrivilege(INTERNAL_STREAM_ACCESS);
        let hasFirstResponder: boolean = 
            userProfile.hasPrivilege(FIRST_RESPONDER_STREAM_ACCESS) || hasAldot;
        let hasMedia: boolean = 
            userProfile.hasPrivilege(MEDIA_STREAM_ACCESS) || hasFirstResponder;
        let hasStakeholder: boolean = 
            userProfile.hasPrivilege(PRIVATE_SECTOR_STREAM_ACCESS) || hasMedia;

        switch (item.accessLevel) {
            case EATStreamAccessLevel.Public:
                return isNonExpiredUser;
            case EATStreamAccessLevel.Stakeholder:
                return isNonExpiredUser && hasStakeholder;
            case EATStreamAccessLevel.Media:
                return isNonExpiredUser && hasMedia;
            case EATStreamAccessLevel.FirstResponder:
                return isNonExpiredUser && hasFirstResponder;
            case EATStreamAccessLevel.ALDOT:
                return isNonExpiredUser && hasAldot;
            default:
                return false;
        }
    }

    renderLivestreamModal = (item: IATCamera | null): React.ReactNode => {
        if (!item) return null;

        return (
            <div 
                className='modal fade' ref={this.modalRef} id={`${item.id}_livestreamModal`} 
                tabIndex={-1} role='dialog' data-keyboard='false' data-backdrop='static' 
                aria-labelledby={`${item.id}_modalTitle`} aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id={`${item.id}_modalTitle`}>
                                Livestream
                            </h5>
                            <button 
                                type="button" className="close" data-dismiss="modal" 
                                aria-label="Close" onClick={this.closeLivestreamClick}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <video
                                className='av-stream-modal-player video-js vjs-default-skin'
                                controls={false}
                                playsInline={true}
                                preload='auto'
                                autoPlay={true}
                                ref={this.streamNodeRef}
                                onDoubleClick={this.toggleFullscreen}
                            >
                                <source
                                    src={item.hlsUrl}
                                    type="application/x-mpegURL"
                                />
                            </video>
                        </div>
                        <div className='modal-footer'>
                            <button
                                className='btn btn-block btn-primary'
                                onClick={this.closeLivestreamClick}
                            >
                                <i className='fa fa-times-circle'></i> Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCameraElements = (
        items: IATCamera[] | null, 
        selectedRegion: EATRegion
    ): React.ReactNode => {
        if (items && items.length > 0) {
            return items.map(
                (item, index) =>
                    <Camera
                        key={`${index}_${item.id}_${item.responsibleRegion}`}
                        item={item} isAdminView={true}
                        isAvailable={
                            this.isAvailableToUser(item, this.props.user, this.props.profile.userProfile) && 
                            nuon(item.hlsUrl) && 
                            item.hlsUrl !== ''
                        }
                        viewLivestreamClick={this.viewLivestreamClick}
                    />
            );
        }
        else {
            let alertMessage: string = (selectedRegion === "Unknown")
                ? 'Select a region from the toolbar to view cameras for that region'
                : 'No cameras found for given query';

            return (
                <div className='av-center-alert-container'>
                    <div className='av-primary-alert'>
                        {alertMessage}
                    </div>
                </div>
            );
        }
    }

    renderLegendModal = (displayModal: boolean) => {
        if (displayModal) {
            return (
                <AccessLevelLegendModal 
                    dismissCallback={this.legendModalDismissCallback} 
                />
            );
        }
        else {
            return null;
        }
    }

    render() {
        let { camera, datasource } = this.props;

        if (datasource.loading || datasource.datasources.length === 0) {
            return (
                <Loading />
            );
        }

        let optionBlocks: any = [
            { id: 0, region: EATRegion.ALDOT, title: "ALL", subtitle: "All Regions", hidden: false},        //must manually handle All regions option
            ...datasource.datasources.map(                                                                  //get the rest as function of datasource
                (item) => {
                    return { 
                        id: item.id,
                        region: item.region,
                        title: item.displayAbbreviation, 
                        subtitle: item.displayName, 
                        hidden: false 
                    };
                }
            )
        ];

        let defaultSelection = 0;
        let selectorChange = this.datasourceSelectorChange;
        function callBack(selectionRowState: any){
            selectorChange(optionBlocks[selectionRowState.selectedIndex].region);
        }

        return (
            <div> 
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        <div className='av-cameras-content'>
                            <h1 className='sr-only'>Cameras</h1>
                            <div className="search-suite">
                                <div className="">
                                    <DummySearch 
                                        img={PAGE_IMAGES.DUMMY_SEARCH.SEARCH_ICON} 
                                        callbackAction={this.datasourceSearchChange} 
                                    />
                                    <SelectionRow 
                                        defaultSelection={defaultSelection} optionBlocks={optionBlocks} 
                                        defaultStyles={false} callbackAction={callBack} 
                                    />
                                </div>
                            </div>
                            <div 
                                className={
                                    `av-camera-display${camera.mgmtFilteredItems?.length === 0 
                                        ? ' centered-display' 
                                        : ''
                                    }`
                                }
                            >
                                {this.renderCameraElements(camera.mgmtFilteredItems, this.state.selectedRegion,)}
                                {
                                    this.state.viewingLivestream
                                        ? this.renderLivestreamModal(this.state.livestreamItem)
                                        : ''
                                }
                            </div>
                        </div>
                    </Content>
                </div>
                {this.renderLegendModal(this.state.showLegendModal)}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(CameraManagement));