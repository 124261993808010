import { createUserManager } from 'redux-oidc';
import { UserManagerSettings } from 'oidc-client';

declare var __AUTHORITY_URL__: string;
declare var __CLIENT_URL__: string;

const config = {
    client_id: 'algovideo',
    redirect_uri: `${__CLIENT_URL__}/callback`,
    response_type: 'id_token token',
    scope: 'openid profile algovideo_api',
    authority: __AUTHORITY_URL__,
    post_logout_redirect_uri: `${__CLIENT_URL__}/session_ended`,
    silent_redirect_uri: `${__CLIENT_URL__}/silent_renew`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true
} as UserManagerSettings;

let userManager = createUserManager(config);

export default userManager;