// libraries
import * as React from 'react';
// models & interfaces
import { IAVLegacyVideoBoard } from "@algo/network-manager/models/v3/video";
// components
import {ThemedDropdown} from "@caps-mobile/themed-dropdown";

interface VideoboardSelectorProps {
    videoboards: IAVLegacyVideoBoard[];
    disabled: boolean;
    selectedValue: number;
    onChangeCallback: (videoboardId: number) => void;
    onAddCallback: () => void;
    onRemoveCallback: (videoboardId: number) => void;
}

interface VideoboardSelectorState {
    selectedValue: number;
    selectedIndex: number | null;
}

export default class VideoboardSelector extends React.Component<VideoboardSelectorProps, VideoboardSelectorState> {
    constructor(props: Readonly<VideoboardSelectorProps>) {
        super(props);

        this.videoboardSelected = this.videoboardSelected.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);

        this.state = {
            selectedValue: 0,
            selectedIndex: null,
        } as VideoboardSelectorState;
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.props.selectedValue !== prevProps.selectedValue) {
            this.setState((state, props) => {
                return {
                    ...state,
                    selectedValue: props.selectedValue
                };
            });
        }
    }

    videoboardSelected = (selectedIndex: number) => {
        if (selectedIndex === this.state.selectedIndex) return;

        if (!this.props.videoboards || !this.props.videoboards[selectedIndex]) 
            return;

        let boardId: any = this.props.videoboards[selectedIndex].id;

        this.setState((state, props) => {
            return {
                ...state,
                selectedValue: boardId,
                selectedIndex: selectedIndex
            }
        });

        this.props.onChangeCallback(boardId);
    };

    onAddClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        ($('[data-toggle="tooltip"]') as any).tooltip('hide');

        this.props.onAddCallback();
    }

    onRemoveClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        ($('[data-toggle="tooltip"]') as any).tooltip('hide');

        this.setState({...this.state, selectedIndex: null});
        this.props.onRemoveCallback(this.state.selectedValue);
    }

    render() {
        let { videoboards, disabled } = this.props;

        const listItems = (Array.isArray(videoboards) && videoboards.length > 0) 
            ? videoboards.map((item, index) => item.name || `Untitled Board ${index}`) 
            : [];

        return (
            <div className='av-section'>
                <label  
                    htmlFor={`ddlVideoboard`} 
                    className={`form-label ${disabled ? 'loading' : ''}`}
                >
                    Videoboards
                </label>
                <div className='input-group' >
                    <div style={
                        { 
                            width: "100%", display: "flex", 
                            justifyContent: "space-between", alignItems: "center"
                        }}
                    >
                        <div style={{width: "100%", marginRight: "8px"}}>
                            <ThemedDropdown 
                                height={30} 
                                placeholder={"Select"} disabledPlaceholder={"No Boards"}
                                options={listItems} noDropBorder={false}
                                selectedIndex={this.state.selectedIndex}
                                menuAttached={false} 
                                callback={this.videoboardSelected}
                                searchable={true}
                                disabled={disabled || (videoboards.length === 0)}
                            />
                        </div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <button
                                style={
                                    {
                                        width: 32, height: 32, 
                                        borderTopRightRadius: 0, borderBottomRightRadius: 0
                                    }
                                }
                                className='btn btn-sm btn-outline-danger'
                                data-toggle='tooltip'
                                title='Delete selected videoboard'
                                onClick={this.onRemoveClick}
                                disabled={disabled || this.state.selectedValue === 0}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </button>
                            <button
                                style={{width: 32, height: 32, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                className='btn btn-sm btn-outline-primary'
                                data-toggle='tooltip'
                                title='Start a new videoboard'
                                onClick={this.onAddClick}
                                disabled={disabled}
                            >
                                <i className="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>    
        );
    }
}