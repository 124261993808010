/**************************************
    VERSION NUMBERS
**************************************/
export const API_V1 = "v1.0";
export const API_V2 = "v2.0";
export const API_V3 = "v3.0";

export const CUR_API_VERSION = API_V3;

/**************************************
    ENDPOINT STRINGS
**************************************/

export const V1_ENDPOINTS = {
    cameras: "Cameras",
    datasources: "Datasources",
    legacyVideoboards: "LegacyVideoboards",
    videoboards: "Videoboards",
    profiles: "Users/Profiles",
    users: "Users"
};

export const V2_ENDPOINTS = {
    ...V1_ENDPOINTS,
};

export const V3_ENDPOINTS = {
    ...V2_ENDPOINTS
};

export const CUR_API_ENPOINTS = (version: string) => {

    switch(version){
        case "v1.0":
            return V1_ENDPOINTS;
        case "v2.0":
            return V2_ENDPOINTS;
        case "v3.0":
            return V3_ENDPOINTS;
        default: 
            return V2_ENDPOINTS;
    }
}