import { AVLegacyVideoBoard } from "@algo/network-manager/models/v3/video";
import {
    EditorState, EditorTypes,
    INITIALIZE_VIDEOBOARD_SUCCESS, LOAD_VIDEOBOARD_SUCCESS, INITIALIZE_VIDEOBOARD_FAILURE,
    SET_NAME_SUCCESS, SET_NAME_FAILURE, SET_CAMERA_ITEM_SUCCESS, SET_CAMERA_ITEM_FAILURE,
    REMOVE_CAMERA_ITEM_SUCCESS, REMOVE_CAMERA_ITEM_FAILURE,
    ADD_PAGE_SUCCESS, ADD_PAGE_FAILURE,
    REMOVE_PAGE_SUCCESS, REMOVE_PAGE_FAILURE,
    RESIZE_PAGE_SUCCESS, RESIZE_PAGE_FAILURE,
    SAVE_VIDEOBOARD_BEGIN, SAVE_VIDEOBOARD_SUCCESS, SAVE_VIDEOBOARD_FAILURE,
    SAVE_PAGE_STATE, CLEAR_EDITOR_STATE, CLEAR_EDITOR_ERROR,
    GET_VIDEOBOARD_LIST_BEGIN, GET_VIDEOBOARD_LIST_SUCCESS, GET_VIDEOBOARD_LIST_FAILURE,
    DELETE_VIDEOBOARD_BEGIN, DELETE_VIDEOBOARD_SUCCESS, DELETE_VIDEOBOARD_FAILURE
} from "./types";

let initialState = {
    initialized: false,
    processing: false,
    loading: false,
    error: null,
    list: [],
    videoboard: new AVLegacyVideoBoard(),
    currentPageIndex: 0,
    rowSize: 2,
    columnSize: 2
} as EditorState;

export function editorReducer(state = initialState, action: EditorTypes): EditorState {
    switch (action.type) {
        case INITIALIZE_VIDEOBOARD_SUCCESS:
            return {
                ...state,
                initialized: true,
                videoboard: action.payload.videoboard,
                currentPageIndex: 0,
                rowSize: 2,
                columnSize: 2,
            };
        case LOAD_VIDEOBOARD_SUCCESS:
            return {
                ...state,
                initialized: true,
                videoboard: new AVLegacyVideoBoard(action.payload.response.data),
            };
        case INITIALIZE_VIDEOBOARD_FAILURE:
            return {
                ...state,
                initialized: false,
                error: action.payload.error
            };
        case SET_NAME_SUCCESS:
            return {
                ...state,
                videoboard: action.payload.videoboard
            };
        case SET_NAME_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        case SET_CAMERA_ITEM_SUCCESS:
            return {
                ...state,
                videoboard: action.payload.videoboard
            };
        case SET_CAMERA_ITEM_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        case REMOVE_CAMERA_ITEM_SUCCESS:
            return {
                ...state,
                videoboard: action.payload.videoboard
            };
        case REMOVE_CAMERA_ITEM_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        case ADD_PAGE_SUCCESS:
            return {
                ...state,
                videoboard: action.payload.videoboard
            };
        case ADD_PAGE_FAILURE: 
            return {
                ...state,
                error: action.payload.error
            };
        case REMOVE_PAGE_SUCCESS:
            return {
                ...state,
                videoboard: action.payload.videoboard
            };
        case REMOVE_PAGE_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        case RESIZE_PAGE_SUCCESS:
            return {
                ...state,
                videoboard: action.payload.videoboard
            };
        case RESIZE_PAGE_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        case SAVE_VIDEOBOARD_BEGIN:
            return {
                ...state,
                processing: true
            };
        case SAVE_VIDEOBOARD_SUCCESS: 
            return {
                ...state,
                processing: false,
                videoboard: action.payload.videoboard
            };
        case SAVE_VIDEOBOARD_FAILURE:
            return {
                ...state,
                processing: false,
                error: action.payload.error
            };
        case SAVE_PAGE_STATE:
            return {
                ...state,
                currentPageIndex: action.payload.currentPageIndex,
                rowSize: action.payload.rowSize,
                columnSize: action.payload.columnSize
            };
        case CLEAR_EDITOR_STATE:
            return {
                ...state,
                initialized: false,
                currentPageIndex: 0,
                rowSize: 2,
                columnSize: 2,
                videoboard: new AVLegacyVideoBoard()
            };
        case CLEAR_EDITOR_ERROR:
            return {
                ...state,
                error: null
            };
        case GET_VIDEOBOARD_LIST_BEGIN:
            return {
                ...state,
                loading: true
            };
        case GET_VIDEOBOARD_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload.list,
            };
        case GET_VIDEOBOARD_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case DELETE_VIDEOBOARD_BEGIN:
            return {
                ...state,
                processing: true
            };
        case DELETE_VIDEOBOARD_SUCCESS:
            return {
                ...state,
                processing: false,
                list: action.payload.list
            };
        case DELETE_VIDEOBOARD_FAILURE:
            return {
                ...state,
                processing: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}