// libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, Route, Switch, RouteComponentProps } from 'react-router-dom';
// models & interfaces
import { VIEW_VIDEOBOARD, VIEW_CAMERA } from '../models/Privileges';
// components
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Header from './base/layout/Header';
import ErrorModal from './base/modals/ErrorModal';
import Redirect from './pages/Redirect';
import Error from "./pages/Error";
import OidcCallback from './pages/OidcCallback';
import NotFound from './pages/NotFound';
import AccessDenied from './pages/AccessDenied';
import LoggedOff from './pages/LoggedOff';
import Loading from './pages/Loading';
import OidcSilentRenew from './pages/OidcSilentRenew';
import CameraManagement from './pages/CameraManagement';
import Editor from './pages/Editor';
import SiteGuide from './pages/SiteGuide';
// store
import { AppState } from '../store';
import { DatasourceState } from '../store/datasource/types';
import { ProfileState } from '../store/profile/types';
import { CameraState } from '../store/camera/types';
import { EditorState } from '../store/videoboard/types';

import { User } from 'oidc-client';
import userManager from '../utils/userManager';

import { loadUserProfile, updateUserProfile, clearUserProfileError } from '../store/profile/actions';
import { clearCameraStoreError } from '../store/camera/actions';
import { clearDatasourceError } from '../store/datasource/actions';
import { clearEditorError } from '../store/videoboard/actions';
// constants
import { PROFILE_REFRESH_INTERVAL } from '../utils/AppConstants';

interface StateProps {
    user: User | undefined;
    datasource: DatasourceState;
    profile: ProfileState;
    camera: CameraState;
    editor: EditorState;
}

let mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.oidc.user,
        datasource: state.datasource,
        profile: state.profile,
        camera: state.camera,
        editor: state.editor
    };
}

interface DispatchProps {
    loadUserProfile: typeof loadUserProfile,
    updateUserProfile: typeof updateUserProfile,
    clearUserProfileError: typeof clearUserProfileError,
    clearCameraStoreError: typeof clearCameraStoreError,
    clearDatasourceError: typeof clearDatasourceError,
    clearEditorError: typeof clearEditorError
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loadUserProfile: loadUserProfile,
        updateUserProfile: updateUserProfile,
        clearDatasourceError: clearDatasourceError,
        clearUserProfileError: clearUserProfileError,
        clearCameraStoreError: clearCameraStoreError,
        clearEditorError: clearEditorError
    }, dispatch);
}

type TemplateProps = StateProps & DispatchProps & RouteComponentProps;

interface TemplateState {
    hasError: boolean;
    error: any;
    info: any;
}

class Template extends React.Component<TemplateProps, TemplateState> {
    profileRefresher: NodeJS.Timeout;
    /*
     * SERVICE WORKER CLASS PROPERTY
     * 
     * Property is used to track the service worker
     * that is in control of the current client.
     * 
     */
    serviceWorker: ServiceWorker | null;

    constructor(props: Readonly<TemplateProps>) {
        super(props);

        this.serviceWorker = null;
        this.profileRefresher = 
            setInterval(this.updateProfile, PROFILE_REFRESH_INTERVAL);

        this.state = {
            hasError: false,
            error: null,
            info: null,
        } as TemplateState;
    }

    componentDidMount() {
        this.checkForRedirect();

        this.props.loadUserProfile();

        this.setupServiceWorker();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        this.checkForRedirect();
    }

    componentWillUnmount() {
        clearInterval(this.profileRefresher);
    }

    componentDidCatch(error: any, info: any) {
        this.setState({
            hasError: true,
            error: error,
            info: info
        });

        // TODO: LOGGING FOR CLIENT SIDE ERRORS
    }

    /*
     *  Service worker reference is set to the serviceWorker class property
     *  to be used for message sending from the client to the service worker.
    */
    setupServiceWorker = (): void => {

        let promise = new Promise(resolve => {
            if (navigator?.serviceWorker?.controller) {
                return resolve(`navigator.serviceWorker.controller found`);
            }

            navigator?.serviceWorker?.addEventListener(
                'controllerchange', 
                e => resolve(`navigator.serviceWorker event: controllerchange`)
            );
        });

        promise.then(() => {
            this.serviceWorker = navigator?.serviceWorker?.controller;
        });
    }

    checkForRedirect = (): void => {
        let doNotCheckForUser: string[] = [
            '/callback',
            '/silent_renew',
            '/session_ended'
        ];

        // Redirect to capture active session in new tab/window
        if (window.location && !doNotCheckForUser.includes(window.location.pathname)) {
            userManager.getUser()
                .then(userManagerUser => {
                    if (!userManagerUser || userManagerUser.expired) {
                        userManager.signinRedirect({
                            data: {
                                path: window.location.pathname
                            }
                        });
                    }
                    else {
                        /*
                         * ACCESS TOKEN MESSAGE SENDING TO SERVICE WORKER
                         * 
                         * If the service worker is in control of the client
                         * then post a message to the SW that includes user access token.
                         * 
                         */ 
                        if (this.serviceWorker) {
                            this.serviceWorker.postMessage({
                                accessToken: userManagerUser.access_token
                            });
                        }
                    }
                }, reject => {
                    throw reject;
                })
                .catch(error => {
                    throw error;
                });
        }
    }

    dismissCameraError = (): void => {
        this.props.clearCameraStoreError();
    }

    dismissDatasourceError = (): void => {
        this.props.clearDatasourceError();
    }

    dismissVideoboardEditorError = (): void => {
        this.props.clearEditorError();
    }

    dismissProfileError = (): void => {
        this.props.clearUserProfileError();
    }

    injectErrorModal = (): React.ReactNode => {
        let {
            camera,
            datasource,
            profile,
            editor
        } = this.props;

        if (camera.error) {
            console.error(`Camera Error: title: There was a problem fulfilling a camera request`, camera.error);
            return null;
        }
        else if (datasource.error) {
            return (
                <ErrorModal 
                    dismissCallback={this.dismissDatasourceError} 
                    title={`There was a problem fulfilling a datasource request`} 
                    error={datasource.error} 
                />
            );
        }
        else if (editor.error) {
            return ( 
                <ErrorModal 
                    dismissCallback={this.dismissVideoboardEditorError} 
                    title={`There was a problem fulfilling a videoboard editor request`} 
                    error={editor.error} 
                />
            );
        }
        else if (profile.error) {
            console.error(`Profile Error: title: There was a problem fulfilling a user profile request.`, profile.error);
            return null;
        }
        else {
            return null;
        }
    }

    updateProfile = (): void => {
        this.props.updateUserProfile();
    }

    render() {
        let {
            user,
            profile,
            location
        } = this.props;

        // This is yuck but avoids too many flashes of landing pages while state loads
        let defaultLanding = (!user || user.expired) 
            ? Redirect
            : (
                (profile.initialized)
                    ? AccessDenied 
                    : Loading
            );

        let displayErrorModal = this.injectErrorModal();

        if (this.state.hasError) return <Error />

        // used to validate whether a User currently has access to a Route, based on
        // their User data and privilege
        // return True only if non-expired user exists and has the optionally supplied property
        const checkRouteAccess = (privilege: any = null) => {

            // confirm non-expired user
            let result = (
                this.props.user &&
                !this.props.user.expired
            );
            
            // confirm user has this privilege
            if (privilege !== null){
                result = (result && profile.userProfile.hasPrivilege(privilege))
            }

            return result;
        };

        return (
            <div>
                <Header />
                <TransitionGroup>
                    <CSSTransition key={location.key} classNames='fade' timeout={{ enter: 500, exit: 300 }}>
                        <Switch location={location}>
                            <Route exact path='/' component={checkRouteAccess(VIEW_VIDEOBOARD) ? Editor : defaultLanding} />
                            <Route path='/cameras' component={checkRouteAccess(VIEW_CAMERA) ? CameraManagement : defaultLanding } />
                            <Route path='/guide' component={checkRouteAccess() ? SiteGuide : defaultLanding} />
                            <Route path='/callback' component={OidcCallback} />
                            <Route path='/silent_renew' component={OidcSilentRenew} />
                            <Route path='/session_ended' component={LoggedOff} />
                            <Route path='/access_denied' component={AccessDenied} />
                            <Route component={NotFound} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>

                {displayErrorModal}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(Template));